import axios from "axios";

declare global {
  interface Window {
    API_URL: string;
  }
}

console.log(process.env.NODE_ENV);
console.log(window.API_URL);

export const BASE_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3000/"
    : window.API_URL;
export const URL_USER_AUTHENTICATE = "accounts/login";
export const URL_REFRESH_TOKEN = "accounts/refresh";

const api = axios.create({
  baseURL: BASE_URL,
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem("accessToken");
  if (config.headers) {
    config.headers.Authorization = `Bearer ${token}` || "";
  }
  return config;
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalReq = error.config;
    if (
      error.response.status == 401 &&
      !originalReq._retry &&
      error.response.config.url != URL_USER_AUTHENTICATE
    ) {
      originalReq._retry = true;

      return axios
        .post(BASE_URL + URL_REFRESH_TOKEN, {})
        .then((res) => {
          if (res.data.status == 200) {
            return axios(originalReq);
          }
        })
        .catch((_error) => {
          window.location.href = "/login";
        });
    }
    console.log("Rest promise error");

    // If not matched , then return the error
    return error;
  }
);
export { api };
