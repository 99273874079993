import React from "react";

interface ConfirmModalFormProps {
  header: string;
  message: string;
  additionalInfo?: string;
  icon: "danger" | "information";
  confirmAction: () => Promise<void>;
  showAction: (show: boolean) => void;
}

const styles = {};

export const ConfirmModal: React.FunctionComponent<ConfirmModalFormProps> = ({
  header,
  message,
  additionalInfo,
  confirmAction,
  showAction,
}) => {
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-2xl font-semibold pr-10">{header}</h3>
              <button
                className="p-1 ml-auto border-0 float-right text-3xl leading-none"
                onClick={() => showAction(false)}
              >
                <span className="text-black h-6 w-6 text-lg block">❌</span>
              </button>
            </div>
            {/*body*/}
            <div className="relative p-4 flex-auto  flex justify-center">
              <span className="text-8xl">⚠️</span>
            </div>
            <div className="relative px-6 mb-6 flex-auto text-center mt-5">
              {message}
            </div>
            {additionalInfo && (
              <div className="relative px-6 mb-6 flex-auto mt-2 text-center">
                {additionalInfo}
              </div>
            )}
            {/*footer*/}
            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => showAction(false)}
              >
                Ei
              </button>
              <button
                className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => {
                  confirmAction();
                  showAction(false);
                }}
              >
                Kyllä
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};
