import React, { useEffect, useState } from "react";
import { api } from "../../api/axiosCalls";
import { MainWrapper } from "../../components/common/MainWrapper";
import { AddAccountModal } from "./AddAccountModal";
import { ChangePasswordModalAdmin } from "./ChangePasswordModalAdmin";
import { DeleteAccountModal } from "./DeleteAccountModal";
import { EditAccountModal } from "./EditAccountModal";

interface AccountListRow {
  id: number;
  uuid: string;
  manual_identifier: string;
  first_name: string;
  last_name: string;
  email: string;
  activated: number;
  last_login: string;
  account_level: number;
  customer_id: string;
}

export const ProfileAdmin: React.FunctionComponent<{}> = () => {
  const [showPasswordChangeModal, setShowPasswordChangeModal] = useState(false);
  const [showAddAccountModal, setShowAddAccountModal] = useState(false);
  const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);
  const [showEditAccountModal, setShowEditAccountModal] = useState(false);

  const [accounts, setAccounts] = useState<AccountListRow[]>([]);
  const [activeAccountEmail, setActiveAccountEmail] = useState<string>();

  const [activeAccount, setActiveAccount] = useState<AccountListRow>();

  const fetchAccounts = async () => {
    const accountsResponse = await api.get("/accounts/readalladmin");
    if (accountsResponse.status === 200) {
      const { data } = accountsResponse;
      setAccounts(data);
    }
  };

  const activateAccount = async (email: string, value: number) => {
    const accountsResponse = await api.put("/accounts/activate", {
      email,
      value,
    });
    if (accountsResponse.status === 200) {
      fetchAccounts();
    }
  };

  useEffect(() => {
    fetchAccounts();
  }, []);

  const styles = {
    th: "border-b font-medium p-1 pl-1 pt-0 pb-2 text-gray-800 text-left text-xs",
    td: "border-b border-slate-300 p-1 pl-1 text-slate-800 text-xs",
    tdd: "border-b p-2 pl-2 text-slate-800 text-xs",
    labelDefault:
      "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2",
    inputDefault:
      "appearance-none block w-full bg-white text-gray-700 border border-gray-400 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500",
  };

  const headers = [
    "Etunimi",
    "Sukunimi",
    "Sähköposti",
    "Tunniste",
    "Käyttäjätaso",
    "Aktiivinen",
    "",
    "",
  ];

  const userLevels = (level: number) => {
    switch (level) {
      case 0: {
        return "🏢 Asiakas";
      }
      case 1: {
        return "👥 Käyttäjä";
      }
      case 2: {
        return "⭐️ Järjestelmänvalvoja";
      }
    }
  };

  return (
    <>
      <MainWrapper titleText="Käyttäjähallinta">
        <div className="w-full flex justify-center mt-5">
          <div className="mx-3 mb-5 shadow bg-white text-black px-4 rounded min-w-max w-3/5 p-5">
            <div className="flex flex-col md:w-8/12">
              <div className="flex flex-row justify-between">
                <div>
                  <h4 className="text-black font-bold text-2xl mb-10">
                    Käyttäjät
                  </h4>
                </div>
                <div>
                  <button
                    className="bg-green-700 hover:bg-green-600 text-white font-bold py-2 px-4 rounded text-xs w-fit mt-4"
                    onClick={() => {
                      setShowAddAccountModal(true);
                    }}
                  >
                    Lisää käyttäjä
                  </button>
                </div>
              </div>

              <table className="border-collapse table-auto w-full text-sm">
                <thead>
                  <tr>
                    {headers.map((headerName) => (
                      <th className={styles.th} key={headerName}>
                        {headerName}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {accounts &&
                    accounts.length > 0 &&
                    accounts.map((row) => (
                      <>
                        {row.account_level != 0 && (
                          <tr
                            key={row.id}
                            className="hover:bg-green-100 cursor-pointer"
                          >
                            <td className={styles.td}>{row.first_name}</td>
                            <td className={styles.td}>{row.last_name}</td>
                            <td className={styles.td}>{row.email}</td>
                            <td className={styles.td}>
                              {row.manual_identifier}
                            </td>
                            <td className={styles.td}>
                              {userLevels(row.account_level)}
                            </td>
                            <td className={styles.td}>{row.activated}</td>
                            <td className={styles.td}>
                              <button
                                className="bg-blue-700 hover:bg-blue-600 text-white font-bold py-2 px-2 rounded my-2 text-xs w-fit"
                                onClick={() => {
                                  setActiveAccount(row);
                                  setShowPasswordChangeModal(true);
                                }}
                              >
                                Vaihda salasana
                              </button>
                            </td>
                            <td className={styles.td}>
                              <button
                                className="bg-blue-700 hover:bg-blue-600 text-white font-bold py-2 px-2 rounded my-2 text-xs w-fit "
                                onClick={() => {
                                  activateAccount(
                                    row.email,
                                    row.activated == 1 ? 0 : 1
                                  );
                                }}
                              >
                                {row.activated == 1 ? "Deaktivoi" : "Aktivoi"}
                              </button>
                            </td>
                            <td className={styles.td}>
                              <button
                                className="bg-blue-700 hover:bg-blue-600 text-white font-bold py-2 px-2 rounded my-2 text-xs w-fit"
                                onClick={() => {
                                  setActiveAccount(row);
                                  setShowEditAccountModal(true);
                                }}
                              >
                                Muokkaa
                              </button>
                            </td>
                            <td className={styles.td}>
                              <button
                                className="bg-blue-700 hover:bg-blue-600 text-white font-bold py-2 px-2 rounded my-2 text-xs w-fit"
                                onClick={() => {
                                  setActiveAccount(row);
                                  setShowDeleteAccountModal(true);
                                }}
                              >
                                Poista
                              </button>
                            </td>
                          </tr>
                        )}
                      </>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="w-full flex justify-center mt-5">
          <div className="mx-3 mb-5 shadow bg-white text-black px-4 rounded min-w-max w-3/5 p-5">
            <div className="flex flex-col md:w-8/12">
              <div className="flex flex-row justify-between">
                <div>
                  <h4 className="text-black font-bold text-2xl mb-10">
                    Asiakastunnukset
                  </h4>
                </div>
                <div>
                  <button
                    className="bg-green-700 hover:bg-green-600 text-white font-bold py-2 px-4 rounded text-xs w-fit mt-4"
                    onClick={() => {
                      setShowAddAccountModal(true);
                    }}
                  >
                    Lisää käyttäjä
                  </button>
                </div>
              </div>

              <table className="border-collapse table-auto w-full text-sm">
                <thead>
                  <tr>
                    {headers.map((headerName) => (
                      <th className={styles.th} key={headerName}>
                        {headerName}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {accounts &&
                    accounts.length > 0 &&
                    accounts.map((row) => (
                      <>
                        {row.account_level === 0 && (
                          <tr
                            key={row.id}
                            className="hover:bg-green-100 cursor-pointer"
                          >
                            <td className={styles.td}>{row.first_name}</td>
                            <td className={styles.td}>{row.last_name}</td>
                            <td className={styles.td}>{row.email}</td>
                            <td className={styles.td}>
                              {row.manual_identifier}
                            </td>
                            <td className={styles.td}>
                              {userLevels(row.account_level)}
                            </td>
                            <td className={styles.td}>{row.activated}</td>
                            <td className={styles.td}>
                              <button
                                className="bg-blue-700 hover:bg-blue-600 text-white font-bold py-2 px-2 rounded my-2 text-xs w-fit"
                                onClick={() => {
                                  setActiveAccount(row);
                                  setShowPasswordChangeModal(true);
                                }}
                              >
                                Vaihda salasana
                              </button>
                            </td>
                            <td className={styles.td}>
                              <button
                                className="bg-blue-700 hover:bg-blue-600 text-white font-bold py-2 px-2 rounded my-2 text-xs w-fit "
                                onClick={() => {
                                  activateAccount(
                                    row.email,
                                    row.activated == 1 ? 0 : 1
                                  );
                                }}
                              >
                                {row.activated == 1 ? "Deaktivoi" : "Aktivoi"}
                              </button>
                            </td>
                            <td className={styles.td}>
                              <button
                                className="bg-blue-700 hover:bg-blue-600 text-white font-bold py-2 px-2 rounded my-2 text-xs w-fit"
                                onClick={() => {
                                  setActiveAccount(row);
                                  setShowEditAccountModal(true);
                                }}
                              >
                                Muokkaa
                              </button>
                            </td>
                            <td className={styles.td}>
                              <button
                                className="bg-blue-700 hover:bg-blue-600 text-white font-bold py-2 px-2 rounded my-2 text-xs w-fit"
                                onClick={() => {
                                  setActiveAccount(row);
                                  setShowDeleteAccountModal(true);
                                }}
                              >
                                Poista
                              </button>
                            </td>
                          </tr>
                        )}
                      </>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {showPasswordChangeModal && activeAccount ? (
          <ChangePasswordModalAdmin
            showAction={setShowPasswordChangeModal}
            email={activeAccount.email}
          />
        ) : null}
        {showAddAccountModal ? (
          <AddAccountModal
            showAction={setShowAddAccountModal}
            refreshAction={fetchAccounts}
          />
        ) : null}
        {showDeleteAccountModal && activeAccount ? (
          <DeleteAccountModal
            showAction={setShowDeleteAccountModal}
            email={activeAccount.email}
            refreshAction={fetchAccounts}
          />
        ) : null}
        {showEditAccountModal && activeAccount ? (
          <EditAccountModal
            showAction={setShowEditAccountModal}
            accountLevel={activeAccount.account_level}
            customerId={activeAccount.customer_id}
            email={activeAccount.email}
            firstName={activeAccount.first_name}
            lastName={activeAccount.last_name}
            refreshAction={fetchAccounts}
          />
        ) : null}
      </MainWrapper>
    </>
  );
};
