import { startOfDay } from "date-fns";
import React, { useState } from "react";
import ReactDatePicker from "react-datepicker";

interface ThreePartBillingButtonProps {
  editAction: (id: string, date: Date) => void;
  text: string | null;
  id: string;
}

export const ThreePartBillingButton: React.FC<ThreePartBillingButtonProps> = ({
  editAction,
  text,
  id,
}) => {
  const [editActive, setEditActive] = useState(false);

  return (
    <>
      {text && text.length > 0 && !editActive ? (
        <>
          <button
            onClick={() => {
              setEditActive(true);
            }}
            className="rounded-l-lg p-2 shadow-sm bg-slate-200 hover:bg-slate-300"
          >
            ✏️
          </button>
          <button className="rounded-none p-2 shadow-sm bg-slate-50 border border-l border-r">
            {text}
          </button>
          <button
            onClick={() => {
              editAction(id, new Date("1970-01-01"));
              setEditActive(false);
            }}
            className="rounded-r-lg p-2 shadow-sm bg-slate-200 hover:bg-slate-300"
          >
            ❌
          </button>
        </>
      ) : editActive ? (
        <div className="relative">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none z-40">
            <svg
              className="w-5 h-5 text-gray-500 dark:text-gray-400"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                clipRule="evenodd"
              ></path>
            </svg>
          </div>
          <ReactDatePicker
            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-fit pl-10 p-1 z-40"
            locale="fi"
            dateFormat="dd/MM/yyyy"
            selected={new Date()}
            disabledKeyboardNavigation
            onChange={(date) => {
              date && editAction(id, startOfDay(date));
              setEditActive(false);
            }}
          />
        </div>
      ) : (
        <button
          onClick={() => {
            setEditActive(true);
          }}
          className="rounded p-2 shadow-sm bg-slate-200 hover:bg-slate-300"
        >
          ✏️
        </button>
      )}
    </>
  );
};
