import React, { useEffect, useState } from "react";
import { api } from "../../api/axiosCalls";
import * as yup from "yup";
import Select from "react-select";
import { CustomerRow } from "../customers/CustomersView";

interface EditAccountModalProps {
  showAction: (show: boolean) => void;
  email: string;
  firstName: string;
  lastName: string;
  accountLevel: number;
  customerId: string;
  refreshAction: () => void;
}

const styles = {
  th: "border-b font-medium p-1 pl-1 pt-0 pb-2 text-gray-800 text-left text-xs",
  td: "border-b border-slate-300 p-1 pl-1 text-slate-800 text-xs",
  tdd: "border-b p-2 pl-2 text-slate-800 text-xs",
  labelDefault:
    "block uppercase tracking-wide text-gray-700 text-xs font-bold my-2",
  inputDefault:
    "appearance-none block w-full bg-white text-gray-700 border border-gray-400 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500",
};

interface FormData {
  old_email: string;
  email: string;
  firstName: string;
  lastName: string;
  accountLevel: number;
  customerId: string;
}

export const EditAccountModal: React.FunctionComponent<
  EditAccountModalProps
> = ({
  showAction,
  refreshAction,
  email,
  firstName,
  lastName,
  accountLevel,
  customerId,
}) => {
  const [formData, setFormData] = useState<FormData>({
    old_email: email,
    email,
    firstName,
    lastName,
    accountLevel,
    customerId,
  });

  const handleEdit = (value: string, field: string) => {
    setFormData({ ...formData, [field]: value });
  };

  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [customers, setCustomers] = useState<CustomerRow[]>([]);
  const [defaultSelectedCustomer, setDefaultSelectedCustomer] =
    useState<CustomerRow>();

  let schema = yup.object().shape({
    old_email: yup.string().required(),
    email: yup.string().required(),
    firstName: yup.string().required(),
    lastName: yup.string().required(),
  });

  const editAccount = async () => {
    try {
      await schema.validate(formData, { abortEarly: false });
      const createCustomerResponse = await api.put("/accounts/edit", {
        ...formData,
      });
      if (createCustomerResponse.status === 200) {
        showAction(false);
        refreshAction();
      }
    } catch (e) {
      console.log(e);
      setValidationErrors((e as any).inner.map((item: any) => item.path));
    }
  };

  const fetchAllCustomers = async () => {
    try {
      const allCustomersResponse = await api.get("/customers/");
      if (allCustomersResponse?.status === 200) {
        const { data } = allCustomersResponse;
        setCustomers(data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchAllCustomers();
  }, []);

  useEffect(() => {
    const defaultSelectedCustomer = customers.find(
      (item: CustomerRow) => item.id == customerId
    );

    setDefaultSelectedCustomer(defaultSelectedCustomer);
  }, [customers]);

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-2xl font-semibold pr-10">Muokkaa tiliä</h3>
              <button className="p-1 ml-auto border-0 float-right text-3xl leading-none">
                <span
                  className="text-black h-6 w-6 text-lg block"
                  onClick={() => showAction(false)}
                >
                  ❌
                </span>
              </button>
            </div>
            {/*body*/}
            <div className="relative p-6 flex-auto bg-slate-200">
              <form className="w-full max-w-lg">
                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full px-3">
                    <label className={styles.labelDefault}>Etunimi</label>
                    <input
                      className={styles.inputDefault}
                      type="text"
                      defaultValue={firstName}
                      onChange={(e) => {
                        handleEdit(e.target.value, "firstName");
                      }}
                    />
                    {validationErrors}
                    {validationErrors.includes("firstName") && (
                      <div className="ml-1 mt-1 text-pink-700">
                        Arvo puuttuu
                      </div>
                    )}
                  </div>
                  <div className="w-full px-3">
                    <label className={styles.labelDefault}>Sukunimi</label>
                    <input
                      className={styles.inputDefault}
                      type="text"
                      defaultValue={lastName}
                      onChange={(e) => {
                        handleEdit(e.target.value, "lastName");
                      }}
                    />
                    {validationErrors.includes("lastName") && (
                      <div className="ml-1 mt-1 text-pink-700">
                        Arvo puuttuu
                      </div>
                    )}
                  </div>
                  <div className="w-full px-3">
                    <label className={styles.labelDefault}>Sähköposti</label>
                    <input
                      className={styles.inputDefault}
                      type="text"
                      defaultValue={email}
                      onChange={(e) => {
                        handleEdit(e.target.value, "email");
                      }}
                    />
                    {validationErrors.includes("email") && (
                      <div className="ml-1 mt-1 text-pink-700">
                        Arvo puuttuu
                      </div>
                    )}
                  </div>
                  <div className="w-1/2 px-3">
                    <label className={styles.labelDefault}>Käyttäjätaso</label>
                    <select
                      onChange={(e) => {
                        handleEdit(e.target.value, "accountLevel");
                      }}
                    >
                      <option value="0" selected={formData.accountLevel === 0}>
                        Asiakas
                      </option>
                      <option value="1" selected={formData.accountLevel === 1}>
                        Käyttäjä
                      </option>
                      <option value="2" selected={formData.accountLevel === 2}>
                        Järjestelmänvalvoja
                      </option>
                    </select>

                    {validationErrors.includes("accountLevel") && (
                      <div className="ml-1 mt-1 text-pink-700 text-xxs">
                        Arvo puuttuu
                      </div>
                    )}
                  </div>
                  <div className="w-1/2 px-3">
                    <label className={styles.labelDefault}>Asiakassidos</label>
                    <Select
                      options={[
                        { value: "", label: "Tyhjä" },
                        ...customers.map((item) => ({
                          value: item.id,
                          label: `${item.name}`,
                        })),
                      ]}
                      placeholder=""
                      value={
                        defaultSelectedCustomer
                          ? {
                              value: defaultSelectedCustomer.id,
                              label: `${defaultSelectedCustomer.name}`,
                            }
                          : undefined
                      }
                      onChange={(e) => {
                        e && handleEdit(e.value, "customerId");
                      }}
                    />
                  </div>
                </div>
              </form>
            </div>
            {/*footer*/}
            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => showAction(false)}
              >
                Peruuta
              </button>
              <button
                className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => editAccount()}
              >
                Tallenna muutokset
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};
