import create from "zustand";
import { persist } from "zustand/middleware";

type SettingsStore = {
  // Locations
  resultsPerPageLocation: number;
  setResultsPerPageLocation: (value: number) => void;
  // Upcoming
  resultsPerPageUpcoming: number;
  setResultsPerPageUpcoming: (value: number) => void;
  // Done
  resultsPerPageDone: number;
  setResultsPerPageDone: (value: number) => void;
  // Extinguishers
  resultsPerPageExtinguishers: number;
  setResultsPerPageExtinguishers: (value: number) => void;
};

export const useSettingsStore = create(
  persist<SettingsStore>(
    (set, get) => ({
      // Locations
      resultsPerPageLocation: 20,
      setResultsPerPageLocation: (value: number) =>
        set({ resultsPerPageLocation: value }),
      // Upcoming
      resultsPerPageUpcoming: 20,
      setResultsPerPageUpcoming: (value: number) =>
        set({ resultsPerPageUpcoming: value }),
      // Done
      resultsPerPageDone: 20,
      setResultsPerPageDone: (value: number) =>
        set({ resultsPerPageDone: value }),
      // Extinguishers
      resultsPerPageExtinguishers: 20,
      setResultsPerPageExtinguishers: (value: number) =>
        set({ resultsPerPageExtinguishers: value }),
    }),
    {
      name: "SettingsStore",
    }
  )
);
