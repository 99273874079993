import React, { useState } from "react";
import { MainWrapper } from "../../components/common/MainWrapper";

import profileOutlined from "../../media/profile_outline.png";
import { ChangePasswordModal } from "./ChangePasswordModal";
import { useNavigate } from "react-router-dom";

const ProfileRow: React.FunctionComponent<{
  label: string;
  value: string;
}> = ({ value, label }) => {
  return (
    <div className="flex flex-row justify-between mb-2 relative">
      <div className="w-2/4 font-semibold p-1">{`${label}:`}</div>
      <div className="bg-slate-100 w-2/4 p-1 border-l-2 border-emerald-400">
        {value}
      </div>
    </div>
  );
};

interface ProfileProps {
  customer?: boolean;
}

export const Profile: React.FunctionComponent<ProfileProps> = ({
  customer,
}) => {
  const [showPasswordChangeModal, setShowPasswordChangeModal] = useState(false);

  const navigate = useNavigate();

  return (
    <>
      <MainWrapper titleText="Profiili">
        <div className="w-full flex justify-center mt-5">
          <div className="mx-3 mb-5 shadow bg-white text-black px-4 rounded min-w-max w-3/5 p-5">
            <div className="flex justify-center p-5">
              <img src={profileOutlined} width="80"></img>
            </div>

            <ProfileRow
              label={"Etunimi"}
              value={localStorage.getItem("user_first_name") || ""}
            />
            <ProfileRow
              label={"Sukunimi"}
              value={localStorage.getItem("user_last_name") || ""}
            />
            <ProfileRow
              label={"Sähköposti"}
              value={localStorage.getItem("user_email") || ""}
            />
            <ProfileRow
              label={"Käyttäjätunnus"}
              value={localStorage.getItem("user_manual_identifier") || ""}
            />
            <div className="mt-10">
              <div className="w-full px-2 flex justify-center shadow-sm">
                <button
                  onClick={() => {
                    setShowPasswordChangeModal(true);
                  }}
                  className={`mx-3 mb-5 font-medium cursor-pointer shadow text-lg bg-white text-black px-4 rounded`}
                >
                  Vaihda Salasana
                </button>
              </div>
              {customer ? (
                <div className="w-full px-2 flex justify-center shadow-sm">
                  <button
                    onClick={() => {
                      navigate("/customerreports");
                    }}
                    className={`mx-3 mb-5 font-medium cursor-pointer shadow text-lg bg-white text-black px-4 rounded`}
                  >
                    Takaisin
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        {showPasswordChangeModal ? (
          <ChangePasswordModal showAction={setShowPasswordChangeModal} />
        ) : null}
      </MainWrapper>
    </>
  );
};
