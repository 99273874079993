import { parseISO } from "date-fns";

export const rtzSQL = (date: Date) => {
  const dt = new Date(date);

  function padToTwo(number: number) {
    return number > 9 ? number : "0" + number;
  }

  const year = dt.getFullYear();
  const month = dt.getMonth() + 1; // Date provides month index; not month number
  const day = dt.getDate();

  return `${year}-${padToTwo(month)}-${padToTwo(day)}`;
};

export const parseSQLDate = (date: string) => {
  return parseISO(date.split("T")[0]);
};
