import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import packageJson from "../../package.json";
import logo from "../media/logo.webp";
import profile from "../media/profile_outline.png";

export const Navigationbar: React.FunctionComponent<{}> = ({ children }) => {
  const [dropdownState, setDropdownState] = useState(false);
  const [dropdownState2, setDropdownState2] = useState(false);
  const [dropdownState3, setDropdownState3] = useState(false);

  const usersName = `${localStorage.getItem(
    "user_first_name"
  )} ${localStorage.getItem("user_last_name")}`;
  const usersEmail = `${localStorage.getItem(
    "user_first_name"
  )} ${localStorage.getItem("user_email")}`;

  const location = useLocation();
  const navigate = useNavigate();

  return (
    <>
      <nav className="bg-white border-gray-400 px-2 sm:px-4 py-2.5 rounded">
        <div className="flex flex-wrap justify-between items-center w-full">
          <a href="/" className="flex items-center">
            <img src={logo} className="mr-3 h-6 sm:h-9" alt="Logo" />
            <span className="self-end text-sm font-semibold whitespace-nowrap">
              v {packageJson.version}
            </span>
          </a>
          <div className="flex items-center md:order-2">
            {/* Dropdown profile*/}
            <div
              className={`${
                !dropdownState && "hidden"
              } z-50 text-base list-none bg-white rounded divide-y divide-gray-100 shadow absolute mt-36 -ml-36`}
            >
              <div className="py-3 px-4">
                <span className="block text-sm text-gray-900 dark:text-white">
                  {usersName}
                </span>
                <span className="block text-sm font-medium text-gray-500 truncate dark:text-gray-400">
                  {usersEmail}
                </span>
              </div>
              <ul className="py-1" aria-labelledby="dropdown">
                <li>
                  <Link
                    to="/profile"
                    className="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                    onClick={() => setDropdownState(false)}
                  >
                    Profiili
                  </Link>
                </li>
                <li>
                  <Link
                    to="/profileadmin"
                    className="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                    onClick={() => setDropdownState(false)}
                  >
                    Käyttäjähallinta
                  </Link>
                </li>
                <li>
                  <a
                    href="#"
                    className="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                    onClick={() => {
                      localStorage.clear();
                      navigate(`/login`);
                    }}
                  >
                    Kirjaudu ulos
                  </a>
                </li>
              </ul>
            </div>
            <button
              type="button"
              className="flex mr-3 ml-auto text-sm rounded-full md:mr-0 focus:ring-4 focus:ring-gray-300"
              id="user-menu-button"
              onClick={() => setDropdownState(!dropdownState)}
            >
              <span className="sr-only">Open user menu</span>
              <img className="w-8 h-8" src={profile} />
            </button>
            <button
              type="button"
              onClick={() => setDropdownState2(!dropdownState2)}
              className="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            >
              <svg
                className="w-6 h-6"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <svg
                className="hidden w-6 h-6"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
          <div
            className={`${
              !dropdownState2 && "hidden"
            } justify-between items-center w-full md:flex md:w-auto md:order-1`}
          >
            <ul className="flex flex-col mt-4 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium">
              <li>
                <Link
                  to="/inspectionreport"
                  className={`block py-2 pr-4 pl-3 ${
                    location.pathname === "/inspectionreport"
                      ? "text-blue-700"
                      : "text-grey-700"
                  } border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0  md:hover:text-blue-700 md:p-0`}
                >
                  Raportit
                </Link>
              </li>
              <li>
                <Link
                  to="/upcoming"
                  className={`block py-2 pr-4 pl-3 ${
                    location.pathname === "/upcoming"
                      ? "text-blue-700"
                      : "text-grey-700"
                  } border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0  md:hover:text-blue-700 md:p-0`}
                >
                  Tulevat
                </Link>
              </li>
              <li>
                <Link
                  to="/past"
                  className={`block py-2 pr-4 pl-3 ${
                    location.pathname === "/past"
                      ? "text-blue-700"
                      : "text-grey-700"
                  } border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0  md:hover:text-blue-700 md:p-0`}
                >
                  Tehdyt
                </Link>
              </li>
              <li>
                <Link
                  to="/locations"
                  className={`block py-2 pr-4 pl-3 ${
                    location.pathname === "/locations"
                      ? "text-blue-700"
                      : "text-grey-700"
                  } border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0  md:hover:text-blue-700 md:p-0`}
                >
                  Kohteet
                </Link>
              </li>
              <li>
                <Link
                  to="/customers"
                  className={`block py-2 pr-4 pl-3 ${
                    location.pathname === "/customers"
                      ? "text-blue-700"
                      : "text-grey-700"
                  } border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0  md:hover:text-blue-700 md:p-0`}
                >
                  Asiakkaat
                </Link>
              </li>
              <li>
                <Link
                  to="/extinguishers"
                  className={`block py-2 pr-4 pl-3 ${
                    location.pathname === "/extinguishers"
                      ? "text-blue-700"
                      : "text-grey-700"
                  } border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0  md:hover:text-blue-700 md:p-0`}
                >
                  Sammuttimet
                </Link>
              </li>
              <li>
                <span
                  onClick={() => setDropdownState3(!dropdownState3)}
                  className="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700 cursor-pointer"
                >
                  Hallinnointi
                </span>
                {/* Dropdown Hallinnointi*/}
                <div
                  className={`${
                    !dropdownState3 && "hidden"
                  } z-50 text-base list-none bg-white rounded divide-y divide-gray-100 shadow absolute mt-0 ml-0`}
                >
                  <ul className="py-1" aria-labelledby="dropdown">
                    <li>
                      <Link
                        to="/eventtypesadmin"
                        onClick={() => setDropdownState3(false)}
                        className="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                      >
                        Tapahtumatyypit
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/extinguishersadmin"
                        onClick={() => setDropdownState3(false)}
                        className="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                      >
                        Sammuttimet
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <Link
                  to="/allevents"
                  className={`block py-2 pr-4 pl-3 ${
                    location.pathname === "/allevents"
                      ? "text-blue-700"
                      : "text-grey-700"
                  } border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0  md:hover:text-blue-700 md:p-0`}
                >
                  Tapahtumat
                </Link>
              </li>
              <li>
                <Link
                  to="/infoupcoming"
                  className={`block py-2 pr-4 pl-3 ${
                    location.pathname === "/infoupcoming"
                      ? "text-blue-700"
                      : "text-grey-700"
                  } border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0  md:hover:text-blue-700 md:p-0`}
                >
                  Info-tila
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      {children}
    </>
  );
};
