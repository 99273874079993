import React, { useEffect, useState } from "react";
import { api } from "../../api/axiosCalls";
import * as yup from "yup";
import Select from "react-select";
import { CustomerRow } from "../customers/CustomersView";

interface ChangePasswordModalProps {
  showAction: (show: boolean) => void;
  refreshAction: () => void;
}

const styles = {
  labelDefault:
    "block uppercase tracking-wide text-gray-700 text-xs font-bold my-2",
  inputDefault:
    "appearance-none block w-full bg-white text-gray-700 border border-gray-400 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500",
};

interface FormData {
  email?: string;
  password?: string;
  manualIdentifier?: string;
  firstName?: string;
  lastName?: string;
}

export const AddAccountModal: React.FunctionComponent<
  ChangePasswordModalProps
> = ({ showAction, refreshAction }) => {
  const [formData, setFormData] = useState<FormData | null>(null);

  const handleEdit = (value: string, field: string) => {
    setFormData({ ...formData, [field as keyof FormData]: value });
  };

  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  const [customers, setCustomers] = useState<CustomerRow[]>([]);

  let schema = yup.object().shape({
    email: yup.string().required(),
    password: yup.string().required(),
    manualIdentifier: yup.string().required(),
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    accountLevel: yup.string().required(),
  });

  const addAccount = async () => {
    try {
      await schema.validate(formData, { abortEarly: false });
      const createCustomerResponse = await api.put("/accounts/createnew", {
        ...formData,
      });
      if (createCustomerResponse.status === 200) {
        showAction(false);
        refreshAction();
      }
    } catch (e) {
      console.log(e);
      setValidationErrors((e as any).inner.map((item: any) => item.path));
    }
  };

  const fetchAllCustomers = async () => {
    try {
      const allCustomersResponse = await api.get("/customers/");
      if (allCustomersResponse?.status === 200) {
        const { data } = allCustomersResponse;
        setCustomers(data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchAllCustomers();
  }, []);

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-2xl font-semibold pr-10">
                Lisää käyttäjätili
              </h3>
              <button className="p-1 ml-auto border-0 float-right text-3xl leading-none">
                <span
                  className="text-black h-6 w-6 text-lg block"
                  onClick={() => showAction(false)}
                >
                  ❌
                </span>
              </button>
            </div>
            {/*body*/}
            <div className="relative p-6 flex-auto bg-slate-200">
              <form className="w-full max-w-lg">
                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full px-3">
                    <label className={styles.labelDefault}>Sähköposti</label>
                    <input
                      className={styles.inputDefault}
                      type="email"
                      onChange={(e) => {
                        handleEdit(e.target.value, "email");
                      }}
                    />
                    {validationErrors.includes("old") && (
                      <div className="ml-1 mt-1 text-pink-700">
                        Anna vanha salasana vahvistaaksesi muutoksen
                      </div>
                    )}
                  </div>
                  <div className="w-1/2 px-3">
                    <label className={styles.labelDefault}>Etunimi</label>
                    <input
                      className={styles.inputDefault}
                      type="text"
                      onChange={(e) => {
                        handleEdit(e.target.value, "firstName");
                      }}
                    />
                    {validationErrors.includes("firstName") && (
                      <div className="ml-1 mt-1 text-pink-700">
                        Arvo puuttuu
                      </div>
                    )}
                  </div>
                  <div className="w-1/2 px-3">
                    <label className={styles.labelDefault}>Sukunimi</label>
                    <input
                      className={styles.inputDefault}
                      type="text"
                      onChange={(e) => {
                        handleEdit(e.target.value, "lastName");
                      }}
                    />
                    {validationErrors.includes("lastName") && (
                      <div className="ml-1 mt-1 text-pink-700">
                        Arvo puuttuu
                      </div>
                    )}
                  </div>
                  <div className="w-1/2 px-3">
                    <label className={styles.labelDefault}>
                      Käyttäjätunnus
                    </label>
                    <input
                      className={styles.inputDefault}
                      type="text"
                      onChange={(e) => {
                        handleEdit(e.target.value, "manualIdentifier");
                      }}
                    />
                    {validationErrors.includes("manualIdentifier") && (
                      <div className="ml-1 mt-1 text-pink-700">
                        Arvo puuttuu
                      </div>
                    )}
                  </div>
                  <div className="w-1/2 px-3">
                    <label className={styles.labelDefault}>Salasana</label>
                    <input
                      className={styles.inputDefault}
                      type="text"
                      onChange={(e) => {
                        handleEdit(e.target.value, "password");
                      }}
                    />
                    {validationErrors.includes("manualIdentifier") && (
                      <div className="ml-1 mt-1 text-pink-700">
                        Arvo puuttuu
                      </div>
                    )}
                  </div>
                  <div className="w-1/2 px-3">
                    <label className={styles.labelDefault}>Käyttäjätaso</label>
                    <select
                      onChange={(e) => {
                        handleEdit(e.target.value, "accountLevel");
                      }}
                    >
                      <option value="0"></option>
                      <option value="0">Asiakas</option>
                      <option value="1">Käyttäjä</option>
                      <option value="2">Järjestelmänvalvoja</option>
                    </select>

                    {validationErrors.includes("accountLevel") && (
                      <div className="ml-1 mt-1 text-pink-700 text-xxs">
                        Arvo puuttuu
                      </div>
                    )}
                  </div>
                  <div className="w-1/2 px-3">
                    <label className={styles.labelDefault}>Asiakassidos</label>
                    <Select
                      options={[
                        { value: "", label: "Tyhjä" },
                        ...customers.map((item) => ({
                          value: item.id,
                          label: `${item.name}`,
                        })),
                      ]}
                      placeholder=""
                      onChange={(e) => {
                        e && handleEdit(e.value, "customerId");
                      }}
                      defaultValue={{}}
                    />
                  </div>
                </div>
              </form>
            </div>
            {/*footer*/}
            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => showAction(false)}
              >
                Peruuta
              </button>
              <button
                className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => addAccount()}
              >
                Lisää
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};
