import React, { SetStateAction, useEffect, useState, Dispatch } from "react";

export const ValueDisplay: React.FunctionComponent<{
  title: string;
  value: string;
  changesDone: boolean;
  keyName: string;
  editHandler: (value: string, key: string) => void;
  parentChangeNotifier: Dispatch<SetStateAction<boolean>>;
  highlighted?: boolean;
  readOnly?: boolean;
}> = ({
  title,
  value,
  changesDone,
  parentChangeNotifier,
  editHandler,
  keyName,
  highlighted,
  readOnly,
}) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);

  useEffect(() => {
    !changesDone && isEditing && setIsEditing(false);
  }, [changesDone]);

  const styles = {
    inputEdit:
      "appearance-none block w-full bg-red-100 text-gray-700 border border-gray-700 rounded py-1 px-1 mb-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-sm",
  };

  return (
    <>
      <h4 className="font-bold mt-3">
        <span>{title}</span>
      </h4>
      {isEditing ? (
        <>
          <input
            className={`${styles.inputEdit} ${highlighted && "bg-pink-100"}`}
            type="text"
            defaultValue={value}
            onChange={(e) => {
              parentChangeNotifier(true);
              editHandler(e.target.value, keyName);
            }}
          />
          {highlighted && (
            <span className="text-pink-700 ml-2">*pakollinen</span>
          )}
        </>
      ) : (
        <h1
          className="w-full h-6 cursor-pointer"
          onClick={() => {
            !readOnly && setIsEditing(true);
          }}
        >
          {value}
        </h1>
      )}
    </>
  );
};
