import React, { useState, useEffect } from "react";
import { format, parseISO } from "date-fns";
import { DateCountRow } from "./inspectionReport";

interface DateHelperModalProps {
  showAction: (show: boolean) => void;
  setStartDate: (value: Date | null) => void;
  setEndDate: (value: Date | null) => void;
  dates: DateCountRow[];
}

const styles = {
  labelDefault:
    "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2",
  inputDefault:
    "appearance-none block w-full bg-white text-gray-700 border border-gray-400 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500",
};

export const DateHelperModal: React.FunctionComponent<DateHelperModalProps> = ({
  showAction,
  setStartDate,
  setEndDate,
  dates,
}) => {
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl h-[60vh] overflow-y-auto">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-xl font-semibold pr-10">
                Löydetyt tarkastuspäivät
              </h3>
              <button
                className="p-1 ml-auto border-0 float-right text-3xl leading-none"
                onClick={() => showAction(false)}
              >
                <span className="text-black h-6 w-6 text-lg block">❌</span>
              </button>
            </div>
            {/*body*/}
            <div className="relative p-6 flex-auto">
              {dates.map((date) => (
                <div className="border-b border-solid border-slate-200 my-2">
                  <div className="flex justify-center border-1">
                    <span className="font-bold text-green-600">
                      {format(parseISO(date.inspection_date), "dd/MM/yyyy") ||
                        ""}
                    </span>
                  </div>
                  <div className="flex justify-center">
                    <span className="font-bold text-slate-600 text-xs">
                      {`Tapahtumia: ${date.count}`}
                    </span>
                  </div>
                  <div>
                    <button
                      className="text-slate-700 background-transparent font-bold uppercase px-6 py-2 text-xs outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() =>
                        setStartDate(parseISO(date.inspection_date))
                      }
                    >
                      Aseta alkupäiväksi
                    </button>
                    <button
                      className="text-slate-700 background-transparent font-bold uppercase px-6 py-2 text-xs outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => setEndDate(parseISO(date.inspection_date))}
                    >
                      Aseta loppupäiväksi
                    </button>
                  </div>
                </div>
              ))}
            </div>
            {/*footer*/}
            <div className="flex items-center justify-between p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => showAction(false)}
              >
                Sulje
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};
