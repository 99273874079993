import { INSPECT_MAX_BYTES } from "buffer";
import React from "react";

interface SubNavigatorProps {
  setActive: (page: string) => void;
  active: string;
  items: {
    title: string;
    key: string;
  }[];
}

export const SubNavigator: React.FunctionComponent<SubNavigatorProps> = ({
  setActive,
  items,
  active,
}) => {
  return (
    <div className="w-full px-2 flex justify-center shadow-sm">
      {items.map((item) => (
        <div
          onClick={() => {
            setActive(item.key);
          }}
          className={`mx-3 mb-5 font-medium cursor-pointer shadow text-lg ${
            active === item.key
              ? "bg-green-600 text-white"
              : "bg-white text-black"
          } px-4 rounded`}
        >
          {item.title}
        </div>
      ))}
    </div>
  );
};
