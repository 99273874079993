type FireExtinguisherType = {
  index: number;
  name: string;
};

export const liquidFireExtinguisherTypes: FireExtinguisherType[] = [
  { index: 2, name: "Neste" },
  { index: 7, name: "Neste -30C" },
  { index: 8, name: "Litiumsammutin" },
  { index: 6, name: "Rasvapalosammutin" },
];

export const isLiquidFireExtinguisherType = (
  searchValue: string | number
): boolean => {
  let result = liquidFireExtinguisherTypes.some((extinguisherType) => {
    if (typeof searchValue === "string" && !isNaN(Number(searchValue))) {
      return extinguisherType.index === Number(searchValue);
    } else if (typeof searchValue === "string") {
      return extinguisherType.name === searchValue;
    } else {
      return extinguisherType.index === searchValue;
    }
  });

  return result;
};
