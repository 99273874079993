export const filterByStartingLetter = (data: any[], letter: string) => {
  return data.filter((row) => row.name[0].toUpperCase() === letter);
};

export const filterByIncludes = (data: any[], text: string) => {
  return data.filter((row) => {
    return JSON.stringify(row).toLowerCase().includes(text.toLowerCase());
  });
};

export const filterByActive = (data: any[]) => {
  return data.filter((row) => {
    return row.active === "1";
  });
};

export const filterByNoCustomer = (data: any[]) => {
  return data.filter((row) => {
    return row.customer_number === "0" || row.customer_number === "";
  });
};
