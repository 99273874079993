import React, { useEffect, useState, useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import debounce from "lodash/debounce";
import { api } from "../../api/axiosCalls";

import useTable from "../../hooks/useTable";
import { AddLocationModal } from "./AddLocationModal";
import { Tooltip } from "../../components/Tooltip";
import { useSettingsStore } from "../../stores/UserSettings";
import { MainWrapper } from "../../components/common/MainWrapper";
import { PageSizeSelect } from "../../components/common/PageSizeSelect";
import { PageList } from "../../components/common/PageList";
import { alphabet } from "../../utils/alphabet";
import {
  filterByActive,
  filterByIncludes,
  filterByNoCustomer,
  filterByStartingLetter,
} from "../../api/filters/common";

export interface LocationRow {
  id: string;
  name: string;
  address: string;
  zip: string;
  city: string;
  contact_person: string;
  phone_number: string;
  customer_number: string;
  customer_name: string;
  active: string;
  extra_information: string;
  extra_information_updated: string;
  billed_date: string;
}

interface Sorter {
  value: string;
  direction: number;
}

export const LocationsView: React.FunctionComponent<{}> = () => {
  const { resultsPerPageLocation, setResultsPerPageLocation } =
    useSettingsStore();

  const [showModal, setShowModal] = useState(false);
  const [page, setPage] = useState(1);
  const [allLocations, setAllLocations] = useState<LocationRow[]>([]);
  const [displayedLocations, setDisplayedLocations] = useState<LocationRow[]>(
    []
  );
  const [alphaFilter, setAlphaFilter] = useState("");
  const [uniFilter, setUnitFilter] = useState("");
  const [sorter, setSorter] = useState<Sorter>({
    value: "name",
    direction: 0,
  }); // 0 -> ASC, 1 -> DESC

  const [filterActive, setFilterActive] = useState(false);
  const [noCustomerActive, setNoCustomerActive] = useState(false);

  const itemsPerPage = resultsPerPageLocation;

  const { slice, range } = useTable(displayedLocations, page, itemsPerPage);

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUnitFilter(e.target.value);
  };

  const debouncedChangeHandler = useCallback(debounce(changeHandler, 500), []);

  const fetchAllLocations = async () => {
    const allLocationsResponse = await api.get("/locations");
    if (allLocationsResponse.status === 200) {
      const { data } = allLocationsResponse;
      setAllLocations(data);
    }
  };

  useEffect(() => {
    fetchAllLocations();
  }, []);

  useEffect(() => {
    const alphabetFiltered =
      alphaFilter.length > 0
        ? filterByStartingLetter(allLocations, alphaFilter)
        : allLocations;
    const textFiltered =
      uniFilter.length > 1
        ? filterByIncludes(alphabetFiltered, uniFilter)
        : alphabetFiltered;

    const textFilteredSorted = textFiltered.sort((a, b) =>
      (
        sorter.direction === 0
          ? a[sorter.value] > b[sorter.value]
          : a[sorter.value] < b[sorter.value]
      )
        ? 1
        : (
            sorter.direction === 0
              ? b[sorter.value] > a[sorter.value]
              : b[sorter.value] < a[sorter.value]
          )
        ? -1
        : 0
    );

    const activeFiltered = filterActive
      ? filterByActive(textFilteredSorted)
      : textFilteredSorted;
    const noCustomerFiltered = noCustomerActive
      ? filterByNoCustomer(activeFiltered)
      : activeFiltered;

    setDisplayedLocations([...noCustomerFiltered]);
  }, [
    allLocations,
    alphaFilter,
    uniFilter,
    filterActive,
    noCustomerActive,
    sorter,
  ]);

  const styles = {
    th: "border-b font-medium p-1 pl-1 pt-0 pb-2 text-gray-800 text-left text-xs select-none cursor-pointer",
    td: "border-b border-slate-300 p-1 pl-1 text-slate-800 text-xs",
    labelDefault:
      "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2",
    inputDefault:
      "appearance-none block w-full bg-white text-gray-700 border border-gray-400 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500",
  };

  const headers = [
    { title: "Nimi", value: "name" },
    { title: "As. nro", value: "customer_number" },
    { title: "As. nimi", value: "customer_name" },
    { title: "Katu", value: "address" },
    { title: "Postinumero", value: "zip" },
    { title: "Kaupunki", value: "city" },
    { title: "Yhteyshenkilö", value: "contact_person" },
    { title: "Puhelinnumero", value: "phone_number" },
    { title: "Aktiivinen", value: "active" },
    { title: "Lisätiedot", value: "extra_information" },
    { title: "🔗", value: undefined },
  ];

  return (
    <>
      <MainWrapper
        titleText="Kohteet"
        addButtonTitle="Lisää uusi kohde"
        addModalToggle={setShowModal}
      >
        <div className="p-4">
          <div className="flex justify-between">
            <div className="relative mt-1">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none h-10">
                <svg
                  className="w-5 h-5 text-gray-500 dark:text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </div>
              <input
                type="text"
                id="table-search"
                className="bg-white-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5"
                placeholder="Hae kohteita"
                onChange={debouncedChangeHandler}
              />
            </div>
            <div>
              <div className="flex justify-between">
                <div>
                  <PageSizeSelect
                    pageSize={resultsPerPageLocation}
                    setPageSize={setResultsPerPageLocation}
                  />
                </div>
              </div>
              <div>
                <span className="text-xs">{`Sivu ${page} / ${
                  range[range.length - 1]
                }`}</span>
              </div>
            </div>
          </div>
          <div>
            {alphabet.map((abc) => (
              <button
                key={abc}
                onClick={() => {
                  setPage(1);
                  abc === alphaFilter
                    ? setAlphaFilter("")
                    : setAlphaFilter(abc);
                }}
                className={`${
                  abc === alphaFilter ? "bg-green-300" : "bg-white-500"
                } hover:bg-green-200 text-black text-xs font-bold py-1 px-2 border border-green-700 rounded mr-1 mt-2`}
              >
                {abc}
              </button>
            ))}
          </div>
          <div>
            <button
              onClick={() => setFilterActive(!filterActive)}
              className={
                filterActive
                  ? "bg-orange-500  text-white font-semibold hover:text-white py-2 px-4 border  rounded text-xs mt-2"
                  : "bg-gray-400  text-white font-semibold hover:text-white py-2 px-4 border  rounded text-xs mt-2"
              }
            >
              Näytä vain aktiiviset
            </button>
            <button
              onClick={() => setNoCustomerActive(!noCustomerActive)}
              className={
                noCustomerActive
                  ? "bg-orange-500 text-white font-semibold hover:text-white py-2 px-4 border  rounded text-xs mt-2"
                  : "bg-gray-400  text-white font-semibold hover:text-white py-2 px-4 border  rounded text-xs mt-2"
              }
            >
              Näytä vain kohteet joilla ei asiakasta
            </button>
          </div>
        </div>
        <div className="overflow-x">
          <table className="table-auto overflow-scroll w-full">
            <thead>
              <tr>
                {headers.map((header) => (
                  <th
                    className={styles.th}
                    key={header.value}
                    onClick={() =>
                      header.value &&
                      setSorter({
                        value: header.value,
                        direction:
                          header.value === sorter.value
                            ? sorter.direction === 1
                              ? 0
                              : 1
                            : 1,
                      })
                    }
                  >
                    {`${header.title} ${
                      header.value === sorter.value
                        ? sorter.direction === 0
                          ? "⬇"
                          : "⬆"
                        : ""
                    }`}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white">
              {slice.map((row) => (
                <tr key={row.id} className="hover:bg-green-100 cursor-pointer">
                  <td className={styles.td + " font-bold"}>{row.name}</td>
                  <td className={styles.td}>{row.customer_number}</td>
                  <td className={styles.td}>{row.customer_name}</td>
                  <td className={styles.td}>{row.address}</td>
                  <td className={styles.td}>{row.zip}</td>
                  <td className={styles.td}>{row.city}</td>
                  <td className={styles.td}>{row.contact_person}</td>
                  <td className={styles.td}>{row.phone_number}</td>
                  <td className={styles.td}>
                    {row.active === "1" ? "✅" : "❌"}
                  </td>
                  <td className={styles.td}>
                    {row.extra_information &&
                    row.extra_information.length &&
                    row.extra_information.length > 0 ? (
                      <Tooltip message={row.extra_information}>👀</Tooltip>
                    ) : null}
                  </td>
                  <td className={styles.td}>
                    <a href={`/locations/${row.id}`}>
                      <button className="bg-blue-500 hover:bg-green-700 text-white font-bold py-2 px-8 border border-green-700 rounded">
                        Avaa
                      </button>
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </MainWrapper>
      <PageList pageSetter={setPage} page={page} range={range} />
      {showModal ? <AddLocationModal showAction={setShowModal} /> : null}
    </>
  );
};
