import React, { useEffect, useState } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Image,
} from "@react-pdf/renderer";

import logo from "../../media/logo.png";
import ReactDatePicker from "react-datepicker";
import { fi } from "date-fns/locale";
import { addDays } from "date-fns/esm";
import Select from "react-select";
import { LocationRow } from "../locations/LocationsView";
import { api } from "../../api/axiosCalls";
import format from "date-fns/format";
import { parseISO } from "date-fns";
import { DateHelperModal } from "./DateHelperModal";
import { TypeRow } from "../extinguishers/ExtinguishersAdminView";
import { EventTypeRow } from "../events/EventsAdmin";

interface ReportDataRow {
  index?: number;
  brand: number;
  brand_name: string;
  event_name: string;
  event_type: number;
  model: number;
  model_name: string;
  legacy_model: string;
  inspection_date: string;
  inspection_frequency: number;
  location: string;
  location_name: string;
  qr: string;
  type: number;
  type_name: string;
  size: string;
  year_of_manufacture: string;
  year_of_hydrostatic_test: string;
  year_of_liquid_replacement: string;
  placement: string;
  notes: string;
  inspector: number;
  inspector_name: string;
}

export interface DateCountRow {
  inspection_date: string;
  count: number;
}

// Create styles
const styles = StyleSheet.create({
  viewer: { width: "100%", minHeight: "100vh" },
  page: {
    flexDirection: "column",
    backgroundColor: "white",
    paddingTop: 20,
    paddingHorizontal: 20,
  },
  sideSection: {
    marginTop: 2,
    paddingTop: 2,
    flexGrow: 1,
    flexDirection: "row",
  },
  sideSectionColumn: {
    margin: 2,
    padding: 2,
    flexGrow: 1,
    flexDirection: "column",
  },
  middleSection: {
    margin: 2,
    padding: 2,
    flexGrow: 3,
  },
  topRow: {
    display: "flex",
    flexDirection: "row",
  },
  tableRow: {
    display: "flex",
    flexDirection: "column",
  },
  tableHeaderRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    marginTop: 10,
  },
  tableHeaderRowColumn: {
    display: "flex",
    alignItems: "center",
    paddingBottom: 2,
    marginRight: 1,
  },
  tableDataRow: {
    display: "flex",
    flexDirection: "row",
    textAlign: "center",
    width: "100%",
  },
  tableDataRowColumn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 1,
    minHeight: 20,
  },
  headerText: {
    fontSize: 8,
    marginBottom: 5,
  },
  footer: {
    fontSize: 8,
    position: "absolute",
    bottom: 10,
    left: 0,
    right: 0,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
  dataText: {
    fontSize: 7,
  },
  tableHeaderText: {
    display: "flex",
    fontSize: 8,
  },
  underlined: {
    borderBottom: 1,
    paddingVertical: 1,
  },
  underlinedThick: {
    borderBottom: 2,
    paddingVertical: 1,
  },
  optionsBar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    width: "100%",
    height: 80,
    borderTop: 1,
    borderBottom: 1,
    borderColor: "black",
    margin: 2,
  },
  summaryHeaderText: {
    fontSize: 16,
    marginBottom: 40,
    borderBottom: 1,
    paddingVertical: 1,
  },
  summarySubHeaderText: {
    fontSize: 14,
    marginBottom: 3,
    paddingVertical: 1,
    textDecoration: "underline",
  },
  summaryText: {
    fontSize: 12,
    marginBottom: 2,
    paddingVertical: 1,
  },
});

type Summary = {
  [maintenanceType: string]: {
    [extinguisherType: string]: number;
  };
};

const generateSummary = (
  reportData: ReportDataRow[],
  eventTypes: EventTypeRow[],
  extinguisherTypes: TypeRow[]
): Summary => {
  const summary: Summary = {};

  for (const report of reportData) {
    const eventType = eventTypes.find((et) => +et.id === +report.event_type);
    const extinguisherType = extinguisherTypes.find(
      (ext) => +ext.id === report.type
    );

    if (eventType && extinguisherType) {
      if (!summary[eventType.event_type]) {
        summary[eventType.event_type] = {};
      }
      if (!summary[eventType.event_type][extinguisherType.type]) {
        summary[eventType.event_type][extinguisherType.type] = 0;
      }
      summary[eventType.event_type][extinguisherType.type]++;
    }
  }

  return summary;
};

const listDistinctInspectors = (data: ReportDataRow[]) => {
  return [...new Set(data.map((item: ReportDataRow) => item.inspector_name))];
};

const paginate = (data: ReportDataRow[], chunkSize: number) => {
  let pages = 0;
  const res: ReportDataRow[][] = [];
  while (data.length > 0) {
    const chunk = data.splice(0, pages === 0 ? 15 : chunkSize);
    pages++;
    res.push(chunk);
  }
  return { data: res, pages };
};

// Create Document Component
export const InspectionReportDocument = ({
  location,
  startDate,
  endDate,
}: {
  location: LocationRow;
  startDate: Date;
  endDate: Date;
}) => {
  const [reportData, setReportData] = useState<ReportDataRow[] | null>(null);
  const [allExtinguisherTypes, setAllExtinguisherTypes] = useState<TypeRow[]>(
    []
  );
  const [eventTypes, setEventTypes] = useState<EventTypeRow[] | null>(null);
  const [paginatedReportData, setPaginatedReportData] = useState<
    ReportDataRow[][] | null
  >(null);
  const [pageCount, setPageCount] = useState(0);

  const [uniqueInspectors, setUniqueInspectors] = useState<string[] | null>(
    null
  );

  const fetchAllEventsForReport = async () => {
    const reportEventsResponse = await api.post(
      "reports/alldoneinspectionswithintimeframeforlocation",
      {
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        location: location.id,
      }
    );
    if (reportEventsResponse.status === 200) {
      const { data } = reportEventsResponse;
      setReportData(data);
    }
  };

  const fetchAllExtinguisherTypes = async () => {
    const allTypesResponse = await api.get("/extinguishers/types");
    if (allTypesResponse.status === 200) {
      const { data } = allTypesResponse;
      setAllExtinguisherTypes(data);
    }
  };

  const fetchEventTypes = async () => {
    const eventTypesResponse = await api.get("/events/select");
    if (eventTypesResponse.status === 200) {
      const { data } = eventTypesResponse;
      setEventTypes(data);
    }
  };

  useEffect(() => {
    fetchEventTypes();
  }, []);

  useEffect(() => {
    startDate && endDate && location && fetchAllEventsForReport();
  }, [startDate, endDate, location]);

  useEffect(() => {
    fetchAllExtinguisherTypes();
  }, []);

  useEffect(() => {
    if (reportData) {
      reportData.forEach(function (row, index) {
        row.index = index + 1;
      });
      const paginated = paginate([...reportData], 20);
      setPaginatedReportData(paginated.data);
      setPageCount(paginated.pages);
    }
  }, [reportData]);

  useEffect(() => {
    reportData && setUniqueInspectors(listDistinctInspectors(reportData));
  }, [reportData]);

  type CountItemProps = {
    segmentTitle: string;
    count: { [extinguisherType: string]: number };
  };

  const anyCountsPresent = (count: {
    [extinguisherType: string]: number;
  }): boolean => {
    return Object.values(count).some((value) => value > 0);
  };

  const CountItem: React.FC<CountItemProps> = ({ segmentTitle, count }) => {
    if (!anyCountsPresent(count)) {
      return null;
    }

    return (
      <View style={{ flex: "1 1 100px" }}>
        <Text style={styles.summarySubHeaderText}>{segmentTitle}</Text>
        {Object.entries(count).map(([key, value]) =>
          value > 0 ? (
            <Text key={key} style={styles.summaryText}>
              {`${key}: ${value}`}
            </Text>
          ) : null
        )}
      </View>
    );
  };

  return reportData &&
    allExtinguisherTypes &&
    eventTypes &&
    paginatedReportData ? (
    <Document>
      {paginatedReportData.map((pageData: ReportDataRow[], pageIndex) => (
        <>
          <Page size="A4" style={styles.page} orientation={"landscape"}>
            {pageIndex === 0 ? (
              <View style={styles.topRow}>
                <View style={styles.sideSectionColumn}>
                  <View style={{ flexGrow: 1 }}>
                    <Image src={logo} style={{ width: 120 }}></Image>
                  </View>
                  <View
                    style={{ flexGrow: 3, paddingLeft: 35, marginTop: -10 }}
                  >
                    <Text style={styles.headerText}>Asessorinkatu 9</Text>
                    <Text style={styles.headerText}>20780 Kaarina</Text>
                  </View>
                </View>
                <View style={styles.middleSection}>
                  <View style={{ flexGrow: 1 }}>
                    <Text
                      style={{ ...styles.headerText, ...styles.underlined }}
                    >
                      {`Asiakas / Kohde: ${location.name}`}
                    </Text>
                    <Text
                      style={{ ...styles.headerText, ...styles.underlined }}
                    >
                      {`Osoite: ${location.address}, ${location.zip} ${location.city}`}
                    </Text>
                    <Text
                      style={{ ...styles.headerText, ...styles.underlined }}
                    >
                      {`Yhteyshenkilö: ${location.contact_person} ${location.customer_number}`}
                    </Text>
                    <Text
                      style={{
                        ...styles.headerText,
                        ...styles.underlined,
                        marginTop: 20,
                      }}
                    >
                      Laskutusosoite:
                    </Text>
                    <Text
                      style={{ ...styles.headerText, ...styles.underlined }}
                    >
                      Viite / Lisätiedot laskuun:
                    </Text>
                  </View>
                </View>
                <View style={styles.sideSection}>
                  <View style={{ flexGrow: 1 }}>
                    <Text style={styles.headerText}>
                      {startDate.toISOString() !== endDate.toISOString()
                        ? `Pvm: ${format(startDate, "dd/MM/yyyy", {
                            locale: fi,
                          })} - ${format(endDate, "dd/MM/yyyy", {
                            locale: fi,
                          })}`
                        : `Pvm: ${format(startDate, "dd/MM/yyyy", {
                            locale: fi,
                          })}`}
                    </Text>
                  </View>
                </View>
              </View>
            ) : null}

            <View style={styles.tableRow}>
              <View
                style={{
                  ...styles.tableHeaderRow,
                  ...styles.underlinedThick,
                }}
              >
                <View style={{ ...styles.tableHeaderRowColumn, width: 5 }}>
                  <Text style={styles.tableHeaderText}>nro</Text>
                </View>
                <View style={{ ...styles.tableHeaderRowColumn, width: 80 }}>
                  <Text style={styles.tableHeaderText}>Valmistaja</Text>
                </View>
                <View style={{ ...styles.tableHeaderRowColumn, width: 30 }}>
                  <Text style={styles.tableHeaderText}>Tyyppi</Text>
                </View>
                <View style={{ ...styles.tableHeaderRowColumn, width: 20 }}>
                  <Text style={styles.tableHeaderText}>Koko</Text>
                </View>
                <View style={{ ...styles.tableHeaderRowColumn, width: 80 }}>
                  <Text style={styles.tableHeaderText}>Malli / Täytös</Text>
                </View>
                <View style={{ ...styles.tableHeaderRowColumn, width: 80 }}>
                  <Text style={styles.tableHeaderText}>Tunniste</Text>
                </View>
                <View style={{ ...styles.tableHeaderRowColumn, width: 30 }}>
                  <Text style={styles.tableHeaderText}>KP</Text>
                </View>
                <View style={{ ...styles.tableHeaderRowColumn, width: 30 }}>
                  <Text style={styles.tableHeaderText}>NV</Text>
                </View>
                <View style={{ ...styles.tableHeaderRowColumn, width: 70 }}>
                  <Text style={styles.tableHeaderText}>Tapahtumatyyppi</Text>
                </View>
                <View style={{ ...styles.tableHeaderRowColumn, width: 20 }}>
                  <Text style={styles.tableHeaderText}>Tark.väli</Text>
                </View>
                <View style={{ ...styles.tableHeaderRowColumn, width: 100 }}>
                  <Text style={styles.tableHeaderText}>Sijainti</Text>
                </View>
                <View style={{ ...styles.tableHeaderRowColumn, width: 40 }}>
                  <Text style={styles.tableHeaderText}>Tark. pv</Text>
                </View>
                <View style={{ ...styles.tableHeaderRowColumn, width: 80 }}>
                  <Text style={styles.tableHeaderText}>Huomiot</Text>
                </View>
              </View>
              {pageData &&
                pageData.map((row: ReportDataRow, index: number) => (
                  <>
                    <View
                      style={{ ...styles.tableDataRow, ...styles.underlined }}
                    >
                      <View style={{ ...styles.tableDataRowColumn, width: 5 }}>
                        <Text style={styles.dataText}>{row.index}</Text>
                      </View>
                      <View style={{ ...styles.tableDataRowColumn, width: 80 }}>
                        <Text style={styles.dataText}>{row.brand_name}</Text>
                      </View>
                      <View style={{ ...styles.tableDataRowColumn, width: 30 }}>
                        <Text style={styles.dataText}>{row.type_name}</Text>
                      </View>
                      <View style={{ ...styles.tableDataRowColumn, width: 20 }}>
                        <Text style={styles.dataText}>{row.size}</Text>
                      </View>
                      <View style={{ ...styles.tableDataRowColumn, width: 80 }}>
                        <Text style={styles.dataText}>
                          {row.model_name || row.legacy_model}
                        </Text>
                      </View>
                      <View style={{ ...styles.tableDataRowColumn, width: 80 }}>
                        <Text
                          style={styles.dataText}
                        >{`${row.year_of_manufacture} / ${row.qr}`}</Text>
                      </View>
                      <View style={{ ...styles.tableDataRowColumn, width: 30 }}>
                        <Text style={styles.dataText}>
                          {row.year_of_hydrostatic_test}
                        </Text>
                      </View>
                      <View style={{ ...styles.tableDataRowColumn, width: 30 }}>
                        <Text style={styles.dataText}>
                          {row.year_of_liquid_replacement}
                        </Text>
                      </View>
                      <View style={{ ...styles.tableDataRowColumn, width: 70 }}>
                        <Text style={styles.dataText}>{row.event_name}</Text>
                      </View>
                      <View style={{ ...styles.tableDataRowColumn, width: 20 }}>
                        <Text style={styles.dataText}>
                          {row.inspection_frequency}
                        </Text>
                      </View>
                      <View
                        style={{ ...styles.tableDataRowColumn, width: 100 }}
                      >
                        <Text style={styles.dataText}>{row.placement}</Text>
                      </View>
                      <View style={{ ...styles.tableDataRowColumn, width: 40 }}>
                        <Text style={styles.dataText}>
                          {format(parseISO(row.inspection_date), "dd/MM/yyyy", {
                            locale: fi,
                          })}
                        </Text>
                      </View>
                      <View style={{ ...styles.tableDataRowColumn, width: 80 }}>
                        <Text style={styles.dataText}>{row.notes}</Text>
                      </View>
                    </View>
                  </>
                ))}
            </View>
            <View style={styles.footer} fixed>
              <Text style={styles.headerText}>
                {startDate.toISOString() !== endDate.toISOString()
                  ? `Pvm: ${format(startDate, "dd/MM/yyyy", {
                      locale: fi,
                    })} - ${format(endDate, "dd/MM/yyyy", { locale: fi })}`
                  : `Pvm: ${format(startDate, "dd/MM/yyyy", {
                      locale: fi,
                    })}`}
              </Text>

              <Text style={styles.headerText}>{`Sivu ${
                pageIndex + 1
              } / ${pageCount}`}</Text>

              <Text style={styles.headerText}>
                {pageIndex + 1 == pageCount
                  ? "Allekirjoitus ____________________________________"
                  : null}
              </Text>

              <Text style={styles.headerText}>
                {uniqueInspectors &&
                uniqueInspectors.length > 0 &&
                uniqueInspectors.length > 1
                  ? `Tarkastajien nimet: ${uniqueInspectors.map(
                      (name) => ` ${name}`
                    )}`
                  : `Tarkastajan nimi: ${uniqueInspectors?.toString()}`}
              </Text>
            </View>
          </Page>
        </>
      ))}
      <Page size="A4" style={styles.page} orientation={"landscape"}>
        <View style={{ flexGrow: 1 }}>
          <Text style={styles.summaryHeaderText}>Yhteenveto</Text>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            {Object.entries(
              generateSummary(reportData, eventTypes, allExtinguisherTypes)
            )
              .map(([maintenanceType, extinguisherTypeCounts]) => ({
                maintenanceType,
                extinguisherTypeCounts,
              }))
              .map((summary, index) => (
                <CountItem
                  key={index}
                  segmentTitle={summary.maintenanceType}
                  count={summary.extinguisherTypeCounts}
                />
              ))}
          </View>
        </View>
      </Page>
    </Document>
  ) : null;
};

export const InspectionReport = () => {
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(addDays(new Date(), 90));

  const [allLocations, setAllLocations] = useState<LocationRow[]>([]);
  const [location, setLocation] = useState<LocationRow | null>(null);
  const [reportActive, setReportActive] = useState(false);

  const [allDateCounts, setAllDateCounts] = useState<DateCountRow[]>([]);

  const [showModal, setShowModal] = useState(false);

  const fetchAllDatesForLocation = async (location: string) => {
    const reportEventsResponse = await api.post(
      "events/readalldatesforlocation",
      {
        location,
      }
    );
    if (reportEventsResponse.status === 200) {
      const { data } = reportEventsResponse;
      setAllDateCounts(data);
    }
  };

  useEffect(() => {
    const fetchAllLocations = async () => {
      const allLocationsResponse = await api.get("/locations");
      if (allLocationsResponse.status === 200) {
        const { data } = allLocationsResponse;
        setAllLocations(data);
      }
    };
    fetchAllLocations();
  }, []);

  return (
    <>
      <div style={styles.optionsBar}>
        <div className="relative mt-1 w-fit">
          <div className="flex flex-col mb-2">
            <span className="text-left">Kohde</span>
            <div style={{ width: 300 }}>
              <Select
                options={
                  allLocations &&
                  allLocations.map((item: LocationRow) => ({
                    value: item,
                    label: `${item.name} - ${item.address} ${item.city} ${item.zip}`,
                  }))
                }
                placeholder=""
                onChange={(e) => {
                  setReportActive(false);
                  e && setLocation(e.value);
                  e && fetchAllDatesForLocation(e.value.id);
                  console.log(e);
                }}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col mb-2">
          <span className="text-left">Aikarajaus</span>
          <div className="flex items-center">
            <div className="relative">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none z-50">
                <svg
                  className="w-5 h-5 text-gray-500 dark:text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </div>
              <ReactDatePicker
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 z-100"
                locale="fi"
                dateFormat="dd/MM/yyyy"
                selected={startDate}
                disabledKeyboardNavigation
                onChange={(date) => {
                  setReportActive(false);
                  setStartDate(date);
                }}
              />
            </div>
            <span className="mx-4 text-gray-500">-</span>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none z-50">
                <svg
                  className="w-5 h-5 text-gray-500 dark:text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </div>
              <ReactDatePicker
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 z-100"
                locale="fi"
                dateFormat="dd/MM/yyyy"
                selected={endDate}
                disabledKeyboardNavigation
                onChange={(date) => {
                  setReportActive(false);
                  setEndDate(date);
                }}
              />
            </div>
          </div>
        </div>
        <div className="relative mt-1">
          <div className="flex flex-row mb-2">
            {allDateCounts.length > 0 ? (
              <button
                className="mt-5 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded mx-2"
                onClick={() => setShowModal(true)}
              >
                Etsi tarkastuspäiviä
              </button>
            ) : null}

            <button
              className="mt-5 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 border border-green-700 rounded mx-2"
              onClick={() =>
                location && startDate && endDate && setReportActive(true)
              }
            >
              Muodosta raportti
            </button>
          </div>
        </div>
      </div>
      {showModal ? (
        <DateHelperModal
          showAction={setShowModal}
          dates={allDateCounts}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
      ) : null}
      {reportActive && location && startDate && endDate ? (
        <PDFViewer style={styles.viewer}>
          <InspectionReportDocument
            location={location}
            startDate={startDate}
            endDate={endDate}
          />
        </PDFViewer>
      ) : (
        <div className="flex items-center justify-center h-screen">
          <span className="text-lg -mt-20">
            Valitse pöytäkirjaraportin rajaukset ylhäältä ja muodosta raportti
          </span>
        </div>
      )}
    </>
  );
};
