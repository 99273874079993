import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import LoadingIcons from "react-loading-icons";
import { Navigationbar } from "./components/Navbar";
import "react-toastify/dist/ReactToastify.min.css";

import { Login } from "./screens/login/login";
import { api } from "./api/axiosCalls";
import { LocationsView } from "./screens/locations/LocationsView";
import { SingleLocationView } from "./screens/locations/SingleLocationView";
import { ExtinguishersView } from "./screens/extinguishers/ExtinguishersView";
import { SingleExtinguisherView } from "./screens/extinguishers/SingleExtinguisherView";
import { UpcomingInspections } from "./screens/upcoming/UpcomingInspections";
import { UpcomingInspectionsInfo } from "./screens/infodisplay/Upcoming";
import { PastInspections } from "./screens/done/PastInspections";
import { CustomersView } from "./screens/customers/CustomersView";
import { ExtinguishersAdminView } from "./screens/extinguishers/ExtinguishersAdminView";
import { SingleCustomerView } from "./screens/customers/SingleCustomerView";
import { InspectionReport } from "./screens/pdfReport/inspectionReport";
import { Profile } from "./screens/profile/Profile";
import { ProfileAdmin } from "./screens/profile/ProfileAdmin";
import { EventsView } from "./screens/events/EventsView";
import { EventsAdminView } from "./screens/events/EventsAdmin";
import { CustomerReport } from "./screens/customers/CustomerReport";

const NavbarWrapper: React.FunctionComponent<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <>
      <Navigationbar />
      {children}
    </>
  );
};

const Root: React.FC<{}> = () => {
  const navigate = useNavigate();

  const [actionHelper, setActionHelper] = useState<React.ReactNode>(
    <p className="text-blue-700">Tarkistetaan yhteyttä palvelimeen.</p>
  );

  useEffect(() => {
    const testRequest = async () => {
      try {
        const testResponse = await api.get("accounts/test");
        if (testResponse?.status === 200) {
          setTimeout(() => {
            navigate("/locations", { replace: true });
          }, 1000);
        } else {
          setActionHelper(
            <p className="text-red-700">
              Yhteysvirhe. Kokeile ladata sivu uudestaan.
            </p>
          );
        }
      } catch (e) {
        setActionHelper(
          <p className="text-red-700">
            Yhteysvirhe. Kokeile ladata sivu uudestaan.
          </p>
        );
      }
    };

    testRequest();
  }, []);

  return (
    <>
      <div className="flex items-center justify-center h-screen">
        <div className="flex flex-col items-center">
          <div className="bg-indigo-700 text-white font-bold rounded-lg border shadow-lg p-10 mb-5">
            <LoadingIcons.TailSpin />
          </div>
          <div className="text-2xl">{actionHelper}</div>
        </div>
      </div>
    </>
  );
};

export const App: React.FunctionComponent = () => {
  return (
    <Routes>
      <Route path="/" element={<Root />} />
      <Route path="/login" element={<Login />} />
      <Route path="/customerreports" element={<CustomerReport />} />
      <>
        <Route
          path="/upcoming/"
          element={
            <NavbarWrapper>
              <UpcomingInspections />
            </NavbarWrapper>
          }
        />
        <Route
          path="/locations/"
          element={
            <NavbarWrapper>
              <LocationsView />
            </NavbarWrapper>
          }
        />
        <Route
          path="/extinguishers/"
          element={
            <NavbarWrapper>
              <ExtinguishersView />
            </NavbarWrapper>
          }
        />
        <Route
          path="/locations/:id"
          element={
            <NavbarWrapper>
              <SingleLocationView />
            </NavbarWrapper>
          }
        />
        <Route
          path="/extinguishers/:id"
          element={
            <NavbarWrapper>
              <SingleExtinguisherView />
            </NavbarWrapper>
          }
        />
        <Route
          path="/extinguishersadmin"
          element={
            <NavbarWrapper>
              <ExtinguishersAdminView />
            </NavbarWrapper>
          }
        />
        <Route
          path="/past/"
          element={
            <NavbarWrapper>
              <PastInspections />
            </NavbarWrapper>
          }
        />
        <Route
          path="/customers/"
          element={
            <NavbarWrapper>
              <CustomersView />
            </NavbarWrapper>
          }
        />
        <Route
          path="/customers/:id"
          element={
            <NavbarWrapper>
              <SingleCustomerView />
            </NavbarWrapper>
          }
        />
        <Route path="/infoupcoming/" element={<UpcomingInspectionsInfo />} />
        <Route
          path="/inspectionreport/"
          element={
            <NavbarWrapper>
              <InspectionReport />
            </NavbarWrapper>
          }
        />
        <Route
          path="/profile/"
          element={
            <NavbarWrapper>
              <Profile />
            </NavbarWrapper>
          }
        />
        <Route path="/customerprofile/" element={<Profile customer={true} />} />
        <Route
          path="/profileadmin/"
          element={
            <NavbarWrapper>
              <ProfileAdmin />
            </NavbarWrapper>
          }
        />
        <Route
          path="/allevents/"
          element={
            <NavbarWrapper>
              <EventsView />
            </NavbarWrapper>
          }
        />
        <Route
          path="/eventtypesadmin/"
          element={
            <NavbarWrapper>
              <EventsAdminView />
            </NavbarWrapper>
          }
        />
      </>
    </Routes>
  );
};
