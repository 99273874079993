import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { api } from "../../api/axiosCalls";
import { ToastContainer, toast, Flip } from "react-toastify";
import * as yup from "yup";

import packageJson from "../../../package.json";

import logo from "../../media/default.png";

export const Login: React.FunctionComponent<{}> = () => {
  const [email, setEmail] = useState<string>(
    localStorage.getItem("user_default_login") || ""
  );
  const [password, setPassword] = useState<string>();
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  const navigate = useNavigate();

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const params = {
      email: email,
      password: password,
    };

    let schema = yup.object().shape({
      email: yup.string().email("sähköposti vaadittu").required(),
      password: yup.string().required(),
    });

    try {
      await schema.validate(params, { abortEarly: false });
      try {
        const authResponse = await api.post("accounts/login", params);

        if (authResponse.status === 200) {
          toast.success("Kirjautuminen onnistui!", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: 0,
            toastId: "my_toast",
          });

          localStorage.setItem("user_id", authResponse.data.id);
          localStorage.setItem("accessToken", authResponse.data.accessToken);
          localStorage.setItem("refreshToken", authResponse.data.refreshToken);
          localStorage.setItem("user_email", authResponse.data.email);
          localStorage.setItem("user_first_name", authResponse.data.first_name);
          localStorage.setItem("user_last_name", authResponse.data.last_name);
          localStorage.setItem(
            "user_manual_identifier",
            authResponse.data.manual_identifier
          );
          localStorage.setItem(
            "user_account_level",
            authResponse.data.account_level
          );
          localStorage.setItem("user_default_login", authResponse.data.email);
          setTimeout(() => {
            if (authResponse.data.account_level > 0) {
              navigate("/locations", { replace: true });
            } else {
              navigate("/customerreports", { replace: true });
            }
          }, 3000);
        } else {
          throw new Error();
        }
      } catch (err) {
        if (axios.isAxiosError(err)) {
          if (err.response?.status === 401) {
            toast.error("Tunnistautuminen epäonnistui", {
              position: "top-center",
              autoClose: 7000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              progress: 0,
              toastId: "my_toast",
            });
          }
        } else {
          toast.error("Kirjautuminen epäonnistui, kokeile uudestaan.", {
            position: "top-center",
            autoClose: 7000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: 0,
            toastId: "my_toast",
          });
        }
      }
    } catch (e) {
      setValidationErrors((e as any).inner.map((item: any) => item.path));
    }
  };

  return (
    <main>
      <div className="w-full min-h-screen flex flex-col sm:justify-start items-center pt-6 sm:pt-0 mt-10">
        <div className="w-full sm:max-w-md p-5 mx-auto">
          <img src={logo}></img>
          <h2 className="mb-12 text-center text-5xl font-extrabold -mt-36">
            {packageJson.version}
          </h2>
          <form onSubmit={(e) => handleLogin(e)}>
            <div className="mb-4">
              <label className="block mb-1" htmlFor="email">
                Sähköposti
              </label>
              <input
                type="text"
                placeholder="Sähköposti"
                value={email}
                onChange={(e) => {
                  setValidationErrors([]);
                  setEmail(e.target.value);
                }}
                className="py-2 px-3 border border-gray-300 focus:border-red-300 focus:outline-none focus:ring focus:ring-red-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block w-full"
              />
              {validationErrors.includes("email") && (
                <div className="ml-1 mt-1 text-pink-700">
                  Sähköposti ei kelpaa
                </div>
              )}
            </div>
            <div className="mb-4">
              <label className="block mb-1" htmlFor="password">
                Salasana
              </label>
              <input
                type="password"
                placeholder="*************"
                value={password}
                onChange={(e) => {
                  setValidationErrors([]);
                  setPassword(e.target.value);
                }}
                className="py-2 px-3 border border-gray-300 focus:border-red-300 focus:outline-none focus:ring focus:ring-red-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block w-full"
              />
              {validationErrors.includes("password") && (
                <div className="ml-1 mt-1 text-pink-700">Salasana vaadittu</div>
              )}
            </div>

            <div className="mt-6">
              <button className="w-full inline-flex items-center justify-center px-4 py-2 bg-red-600 border border-transparent rounded-md font-semibold capitalize text-white hover:bg-red-700 active:bg-red-700 focus:outline-none focus:border-red-700 focus:ring focus:ring-red-200 disabled:opacity-25 transition">
                Kirjaudu
              </button>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover
        limit={1}
        transition={Flip}
      />
    </main>
  );
};
