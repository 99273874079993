import React, { useEffect, useState } from "react";

interface PageListProps {
  range: number[];
  page: number;
  pageSetter: (v: number) => void;
}

export const PageList: React.FunctionComponent<PageListProps> = ({
  range,
  page,
  pageSetter,
}) => {
  return (
    <div className="mx-20 flex justify-center flex-wrap">
      {page > 10 ? (
        <button
          onClick={() => pageSetter(page - 10)}
          className="bg-white-500
          hover:bg-green-200 text-black text-xs font-bold py-1 px-2 border border-green-700 rounded mr-1 mt-2"
        >
          {"<<<"}
        </button>
      ) : null}
      {page > 1 ? (
        <button
          onClick={() => pageSetter(page - 1)}
          className="bg-white-500
          hover:bg-green-200 text-black text-xs font-bold py-1 px-2 border border-green-700 rounded mr-1 mt-2"
        >
          {"<"}
        </button>
      ) : null}
      <span
        className="bg-white-500
          text-black text-xs font-bold py-1 border border-green-700 rounded mr-1 mt-2 px-5"
      >
        {page}
      </span>
      <button
        onClick={() => pageSetter(page + 1)}
        className="bg-white-500
          hover:bg-green-200 text-black text-xs font-bold py-1 px-2 border border-green-700 rounded mr-1 mt-2"
      >
        {">"}
      </button>
      <button
        onClick={() => pageSetter(page + 10)}
        className="bg-white-500
          hover:bg-green-200 text-black text-xs font-bold py-1 px-2 border border-green-700 rounded mr-1 mt-2"
      >
        {">>>"}
      </button>
    </div>
  );
};
