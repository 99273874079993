import React from "react";

interface PageSizeSelectProps {
  pageSize: number;
  setPageSize: (v: number) => void;
}

export const PageSizeSelect: React.FunctionComponent<PageSizeSelectProps> = ({
  pageSize,
  setPageSize,
}) => {
  return (
    <select
      className="form-select form-select-sm ml-5 appearance-none block w-auto px-1 py-1 text-xs font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
      aria-label=".form-select-sm example"
      onChange={(e) => setPageSize(+e.target.value)}
    >
      <option selected={pageSize === 10} value="10">
        10
      </option>
      <option value="20" selected={pageSize === 20}>
        20
      </option>
      <option value="30" selected={pageSize === 30}>
        30
      </option>
      <option value="40" selected={pageSize === 40}>
        40
      </option>
      <option value="50" selected={pageSize === 50}>
        50
      </option>
      <option value="100" selected={pageSize === 100}>
        100
      </option>
      <option value="150" selected={pageSize === 150}>
        150
      </option>
      <option value="200" selected={pageSize === 200}>
        200
      </option>
    </select>
  );
};
