import React, { SetStateAction, useEffect, useState, Dispatch } from "react";
import Select from "react-select";
import { LocationRow } from "../../screens/locations/LocationsView";

export const ValueDisplaySelectLocation: React.FunctionComponent<{
  title: string;
  value: string;
  changesDone: boolean;
  keyName: string;
  editHandler: (value: string, key: string) => void;
  parentChangeNotifier: Dispatch<SetStateAction<boolean>>;
  highlighted?: boolean;
  readOnly?: boolean;
  allLocations: LocationRow[];
}> = ({
  title,
  value,
  changesDone,
  parentChangeNotifier,
  editHandler,
  keyName,
  highlighted,
  readOnly,
  allLocations,
}) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);

  useEffect(() => {
    !changesDone && isEditing && setIsEditing(false);
  }, [changesDone]);

  const defaultSelected = allLocations.find(
    (item: LocationRow) => item.name === value
  );

  return (
    <>
      <h4 className="font-bold mt-3">
        <span>{title}</span>
      </h4>
      {isEditing ? (
        <>
          <Select
            options={
              allLocations &&
              allLocations.map((item: LocationRow) => ({
                value: item.id,
                label: `${item.name} - ${item.address} ${item.city} ${item.zip}`,
              }))
            }
            placeholder=""
            onChange={(e) => {
              parentChangeNotifier(true);
              e && editHandler(e.value, "location");
            }}
            defaultValue={
              defaultSelected
                ? {
                    value: defaultSelected.id,
                    label: `${defaultSelected.name} - ${defaultSelected.address} ${defaultSelected.city} ${defaultSelected.zip}`,
                  }
                : undefined
            }
          />
        </>
      ) : (
        <h1
          className="w-full h-6 cursor-pointer"
          onClick={() => {
            !readOnly && setIsEditing(true);
          }}
        >
          {value}
        </h1>
      )}
    </>
  );
};
