import React, { useEffect, useState } from "react";
import { format, parseISO, intervalToDuration } from "date-fns";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { api } from "../../api/axiosCalls";

export interface UpcomingRow {
  id: string;
  name: string;
  zip: string;
  city: string;
  last_inspection_date: string;
  inspection_frequency: string;
  next_inspection_date: string;
  customer_active: number | null;
}

export const UpcomingInspectionsInfo: React.FunctionComponent<{}> = () => {
  const [allUpcoming, setAllUpcoming] = useState<UpcomingRow[]>([]);
  const [displayedUpcomingRows, setDisplayedUpcomingRows] = useState<
    UpcomingRow[]
  >([]);

  const fetchAllUpcomingInspections = async () => {
    const allUpcomingInspectionsResponse = await api.get(
      "/reports/allupcominginspectionsonemonth"
    );
    if (allUpcomingInspectionsResponse.status === 200) {
      const { data } = allUpcomingInspectionsResponse;
      setAllUpcoming(data);
    }
  };

  useEffect(() => {
    fetchAllUpcomingInspections();
  }, []);

  useEffect(() => {
    const packedRows = packLocations(allUpcoming);
    const activeCustomerFilterered = hasActiveCustomer(packedRows);
    setDisplayedUpcomingRows(activeCustomerFilterered);
  }, [allUpcoming]);

  const packLocations = (textFiltered: UpcomingRow[]) => {
    const seen = new Set();
    return textFiltered.filter((row) => {
      const duplicate = seen.has(row.id);
      seen.add(row.id);
      return !duplicate;
    });
  };

  const hasActiveCustomer = (data: UpcomingRow[]) => {
    return data.filter((row) => {
      return row.customer_active === 1 || row.customer_active === null;
    });
  };

  const styles = {
    th: "border-b font-medium p-4 pl-8 pt-0 pb-3 text-white text-left text-2xl",
    td: "border-b border-slate-300 p-4 pl-8 text-white text-2xl",
  };

  const headers = [
    "Nimi",
    "Postinro",
    "Postitmp",
    "Viimeisin tarkastus",
    "Seuraava laskettu tarkastus",
    "Päiviä tarkistukseen",
  ];

  return (
    <>
      <main className="bg-black p-10">
        <div className="flex justify-between">
          <h4 className="text-white font-bold text-4xl">
            Tulevat tarkastukset
          </h4>
          <CountdownCircleTimer
            isPlaying
            duration={60}
            colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
            colorsTime={[60, 30, 10, 0]}
            size={100}
            onComplete={() => {
              fetchAllUpcomingInspections();
              return { shouldRepeat: true, delay: 0.5 }; // repeat animation in 1.5 seconds
            }}
          >
            {({ remainingTime }) => (
              <span className="text-white text-4xl">{remainingTime}</span>
            )}
          </CountdownCircleTimer>
          <h4 className="text-white font-bold text-4xl">
            {format(new Date(), "hh:mm dd/MM/yyyy")}
          </h4>
        </div>
        <div className="mt-4 -mb-3">
          <div className="not-prose relative bg-black rounded-xl overflow-x-auto">
            <div className="relative overflow-auto">
              <div className="shadow-sm overflow-hidden my-8">
                <table className="border-collapse table-auto w-full ">
                  <thead>
                    <tr>
                      {headers.map((headerName) => (
                        <th className={styles.th} key={headerName}>
                          {headerName}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="bg-black">
                    {displayedUpcomingRows.map((row: UpcomingRow) => (
                      <tr key={row.id}>
                        <td className={styles.td + " font-bold"}>{row.name}</td>
                        <td className={styles.td}>{row.zip}</td>
                        <td className={styles.td}>{row.city}</td>
                        <td className={styles.td}>
                          {format(
                            parseISO(row.last_inspection_date),
                            "MM/yyyy"
                          ) || ""}
                        </td>
                        <td className={styles.td}>
                          {format(
                            parseISO(row.next_inspection_date),
                            "MM/yyyy"
                          ) || ""}
                        </td>
                        <td className={styles.td}>
                          {intervalToDuration({
                            start: new Date(),
                            end: parseISO(row.next_inspection_date),
                          }).days || ""}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
