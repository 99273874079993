import React, { useEffect, useState } from "react";
import { api } from "../../api/axiosCalls";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";

interface ChangePasswordModalProps {
  showAction: (show: boolean) => void;
}

const styles = {
  labelDefault:
    "block uppercase tracking-wide text-gray-700 text-xs font-bold my-2",
  inputDefault:
    "appearance-none block w-full bg-white text-gray-700 border border-gray-400 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500",
};

interface FormData {
  old: string;
  new1: string;
  new2: string;
}

export const ChangePasswordModal: React.FunctionComponent<
  ChangePasswordModalProps
> = ({ showAction }) => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState<FormData>({
    old: "",
    new1: "",
    new2: "",
  });

  const handleEdit = (value: string, field: string) => {
    setFormData({ ...formData, [field]: value });
  };

  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  let schema = yup.object().shape({
    old: yup.string().required(),
    new1: yup.string().required(),
    new2: yup
      .string()
      .required()
      .oneOf([yup.ref("new1"), null]),
  });

  const changePassword = async () => {
    const email = localStorage.getItem("user_email");
    try {
      await schema.validate(formData, { abortEarly: false });
      const createCustomerResponse = await api.put("/accounts/changepassword", {
        email,
        password: formData.old,
        newPassword: formData.new1,
      });
      if (createCustomerResponse.status === 200) {
        localStorage.clear();
        navigate(`/login`);
      }
    } catch (e) {
      console.log(e);
      setValidationErrors((e as any).inner.map((item: any) => item.path));
    }
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-2xl font-semibold pr-10">Vaihda salasana</h3>
              <button className="p-1 ml-auto border-0 float-right text-3xl leading-none">
                <span
                  className="text-black h-6 w-6 text-lg block"
                  onClick={() => showAction(false)}
                >
                  ❌
                </span>
              </button>
            </div>
            {/*body*/}
            <div className="relative p-6 flex-auto bg-slate-200">
              <form className="w-full max-w-lg">
                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full px-3">
                    <label className={styles.labelDefault}>
                      Nykyinen salasana
                    </label>
                    <input
                      className={styles.inputDefault}
                      type="password"
                      onChange={(e) => {
                        handleEdit(e.target.value, "old");
                      }}
                    />
                    {validationErrors}
                    {validationErrors.includes("old") && (
                      <div className="ml-1 mt-1 text-pink-700">
                        Anna vanha salasana vahvistaaksesi muutoksen
                      </div>
                    )}
                  </div>
                  <div className="w-full px-3">
                    <label className={styles.labelDefault}>Uusi salasana</label>
                    <input
                      className={styles.inputDefault}
                      type="password"
                      onChange={(e) => {
                        handleEdit(e.target.value, "new1");
                      }}
                    />
                    {validationErrors.includes("new1") && (
                      <div className="ml-1 mt-1 text-pink-700">
                        Arvo puuttuu
                      </div>
                    )}
                  </div>
                  <div className="w-full px-3">
                    <label className={styles.labelDefault}>
                      Uusi salasana uudestaan
                    </label>
                    <input
                      className={styles.inputDefault}
                      type="password"
                      onChange={(e) => {
                        handleEdit(e.target.value, "new2");
                      }}
                    />
                    {validationErrors.includes("new2") && (
                      <div className="ml-1 mt-1 text-pink-700">
                        Arvo puuttuu tai salasanat eivät täsmää
                      </div>
                    )}
                  </div>
                </div>
              </form>
            </div>
            {/*footer*/}
            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => showAction(false)}
              >
                Peruuta
              </button>
              <button
                className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => changePassword()}
              >
                Vaihda salasana
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};
