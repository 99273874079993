import React, { useEffect, useState } from "react";

import * as yup from "yup";
import { api } from "../../api/axiosCalls";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { CustomerRow } from "../customers/CustomersView";
import { formatISO } from "date-fns";

interface AddLocationFormProps {
  showAction: (show: boolean) => void;
}

const styles = {
  labelDefault:
    "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2",
  inputDefault:
    "appearance-none block w-full bg-white text-gray-700 border border-gray-400 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500",
};

export const AddLocationModal: React.FunctionComponent<
  AddLocationFormProps
> = ({ showAction }) => {
  const [formData, setFormData] = useState({});

  const [customers, setCustomers] = useState<CustomerRow[]>([]);

  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  let schema = yup.object().shape({
    name: yup.string().required(),
    address: yup.string().required(),
    zip: yup.string().required(),
    city: yup.string().required(),
  });

  const navigate = useNavigate();

  const handleEdit = (value: string, field: string) => {
    setFormData({
      ...formData,
      [field]: value,
      extra_information_updated: formatISO(new Date()),
    });
  };

  const fetchAllCustomers = async () => {
    try {
      const allCustomersResponse = await api.get("/customers/");
      if (allCustomersResponse.status === 200) {
        const { data } = allCustomersResponse;
        setCustomers(data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchAllCustomers();
  }, []);

  const createLocation = async () => {
    try {
      await schema.validate(formData, { abortEarly: false });
      const createLocationResponse = await api.put("/locations/create", {
        location: formData,
      });
      if (createLocationResponse.status === 200) {
        navigate(`/locations/${createLocationResponse.data.id}`);
      }
    } catch (e) {
      setValidationErrors((e as any).inner.map((item: any) => item.path));
    }
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-3xl font-semibold">Uusi kohde</h3>
              <button
                className="p-1 ml-auto border-0 float-right text-3xl leading-none"
                onClick={() => showAction(false)}
              >
                <span className="text-black h-6 w-6 text-lg block">❌</span>
              </button>
            </div>
            {/*body*/}
            <div className="relative p-6 flex-auto">
              <form className="w-full max-w-lg">
                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full px-3 mb-2">
                    <label className={styles.labelDefault}>Asiakas</label>
                    <Select
                      options={customers.map((item: CustomerRow) => ({
                        value: item.id,
                        label: `${item.name}`,
                      }))}
                      placeholder=""
                      onChange={(e) => {
                        e && handleEdit(e.value, "customer_number");
                      }}
                    />
                  </div>
                  <div className="w-full px-3">
                    <label className={styles.labelDefault}>Nimi</label>
                    <input
                      className={styles.inputDefault}
                      type="input"
                      onChange={(e) => {
                        handleEdit(e.target.value, "name");
                      }}
                    />
                    {validationErrors.includes("name") && (
                      <div className="ml-1 mt-1 text-pink-700">
                        Nimi vaadittu
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-2">
                  <div className="w-full md:w-4/12 px-3 mb-6 md:mb-0">
                    <label className={styles.labelDefault}>Kaupunki</label>
                    <input
                      className={styles.inputDefault}
                      type="text"
                      onChange={(e) => {
                        handleEdit(e.target.value, "city");
                      }}
                    />
                    {validationErrors.includes("city") && (
                      <div className="ml-1 mt-1 text-pink-700">
                        Kaupunki vaadittu
                      </div>
                    )}
                  </div>
                  <div className="w-full md:w-5/12 px-3 mb-6 md:mb-0">
                    <label className={styles.labelDefault}>Katu</label>
                    <input
                      className={styles.inputDefault}
                      type="text"
                      onChange={(e) => {
                        handleEdit(e.target.value, "address");
                      }}
                    />
                    {validationErrors.includes("address") && (
                      <div className="ml-1 mt-1 text-pink-700">
                        Katu vaadittu
                      </div>
                    )}
                  </div>
                  <div className="w-full md:w-3/12 px-3 mb-6 md:mb-0">
                    <label className={styles.labelDefault}>Postinumero</label>
                    <input
                      className={styles.inputDefault}
                      type="text"
                      onChange={(e) => {
                        handleEdit(e.target.value, "zip");
                      }}
                    />
                    {validationErrors.includes("zip") && (
                      <div className="ml-1 mt-1 text-pink-700">
                        Postinumero vaadittu
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-2">
                  <div className="w-full md:w-7/12 px-3 mb-6 md:mb-0">
                    <label className={styles.labelDefault}>Yhteyshenkilö</label>
                    <input
                      className={styles.inputDefault}
                      type="text"
                      onChange={(e) => {
                        handleEdit(e.target.value, "contact_person");
                      }}
                    />
                  </div>
                  <div className="w-full md:w-5/12 px-3 mb-6 md:mb-0">
                    <label className={styles.labelDefault}>Puhelinnumero</label>
                    <input
                      className={styles.inputDefault}
                      type="text"
                      onChange={(e) => {
                        handleEdit(e.target.value, "phone_number");
                      }}
                    />
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-2">
                  <div className="w-full md:w-12/12 px-3 mb-6 md:mb-0">
                    <label className={styles.labelDefault}>Lisätiedot</label>
                    <textarea
                      className={styles.inputDefault}
                      onChange={(e) => {
                        handleEdit(e.target.value, "extra_information");
                      }}
                    />
                  </div>
                </div>
                <div className="md:flex my-6">
                  <label className="md:w-2/3 block text-gray-700 font-bold">
                    <input
                      className="mr-2 leading-tight"
                      type="checkbox"
                      onChange={(e) => {
                        handleEdit(e.target.checked ? "1" : "0", "active");
                      }}
                    />
                    <span className="text-sm">Aktiivinen</span>
                  </label>
                </div>
              </form>
            </div>
            {/*footer*/}
            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => showAction(false)}
              >
                Sulje
              </button>
              <button
                className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => createLocation()}
              >
                Tallenna muutokset
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};
