import React, { useEffect, useState, useCallback } from "react";
import debounce from "lodash/debounce";
import { api } from "../../api/axiosCalls";

import useTable from "../../hooks/useTable";
import { AddExtinguisherModal } from "./AddExtinguishersModal";
import { useSettingsStore } from "../../stores/UserSettings";
import { MainWrapper } from "../../components/common/MainWrapper";
import { PageSizeSelect } from "../../components/common/PageSizeSelect";
import { PageList } from "../../components/common/PageList";
import { filterByIncludes } from "../../api/filters/common";
import { isLiquidFireExtinguisherType } from "../../utils/isLiquidExtinguisher";
import { ThreePartActionButton } from "../../components/ThreePartActionButton";

export interface ExtinguisherRow {
  id: string;
  qr: string;
  type: string;
  type_id: string;
  brand: string;
  brand_id: string;
  model: string;
  model_id: string;
  size: string;
  size_id: string;
  unit: string;
  year_of_manufacture: string;
  year_of_hydrostatic_test: string;
  year_of_liquid_replacement: string;
  inspection_frequency: string;
  location: string;
  placement: string;
  last_inspection_date: string;
  decommissioned: string;
  software_id: string;
  created_on: string;
  deleted: string;
  // resolved only
  name: string;
  resolved_event: string;
  location_id: string;
  legacy_model: string;
  new_model: string;
}

interface Sorter {
  value: string;
  direction: number;
}

export const ExtinguishersView: React.FunctionComponent<{}> = () => {
  const { resultsPerPageExtinguishers, setResultsPerPageExtinguishers } =
    useSettingsStore();

  const [showModal, setShowModal] = useState(false);
  const [page, setPage] = useState(1);
  const [allExtinguishers, setAllExtinguishers] = useState<ExtinguisherRow[]>(
    []
  );
  const [displayedExtinguishers, setDisplayedExtinguishers] = useState<
    ExtinguisherRow[]
  >([]);

  const [uniFilter, setUnitFilter] = useState("");
  const [sorter, setSorter] = useState<Sorter>({
    value: "last_inspection_date",
    direction: 0,
  }); // 0 -> ASC, 1 -> DESC

  const { slice, range } = useTable(
    displayedExtinguishers,
    page,
    resultsPerPageExtinguishers
  );

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUnitFilter(e.target.value);
  };

  const debouncedChangeHandler = useCallback(debounce(changeHandler, 500), []);

  const fetchAllExtinguishers = async () => {
    const allExtinguishersResponse = await api.get("/extinguishers/resolved");
    if (allExtinguishersResponse.status === 200) {
      const { data } = allExtinguishersResponse;
      setAllExtinguishers(data);
    }
  };

  useEffect(() => {
    fetchAllExtinguishers();
  }, []);

  useEffect(() => {
    const textFiltered =
      uniFilter.length > 1
        ? filterByIncludes(allExtinguishers, uniFilter)
        : allExtinguishers;

    const textFilteredSorted = textFiltered.sort((a, b) =>
      (
        sorter.direction === 0
          ? a[sorter.value] > b[sorter.value]
          : a[sorter.value] < b[sorter.value]
      )
        ? 1
        : (
            sorter.direction === 0
              ? b[sorter.value] > a[sorter.value]
              : b[sorter.value] < a[sorter.value]
          )
        ? -1
        : 0
    );

    setDisplayedExtinguishers(
      uniFilter.length > 3
        ? textFilteredSorted
        : textFilteredSorted.slice(0, 50)
    );
  }, [uniFilter, allExtinguishers, sorter]);

  const styles = {
    th: "border-b font-medium p-1 pl-1 pt-0 pb-2 text-gray-800 text-left text-xs select-none cursor-pointer",
    td: "border-b border-slate-300 p-1 pl-1 text-slate-800 text-xs",
    tdd: "border-b p-2 pl-2 text-slate-800 text-xs",
    labelDefault:
      "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2",
    inputDefault:
      "appearance-none block w-full bg-white text-gray-700 border border-gray-400 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500",
  };

  const headers = [
    { title: "ID", value: "id" },
    { title: "QR", value: "qr" },
    { title: "Kohde", value: "location" },
    { title: "Tyyppi", value: "type" },
    { title: "Merkki", value: "brand" },
    { title: "Malli", value: "model" },
    { title: "Koko", value: "size" },
    { title: "Valmistusvuosi", value: "year_of_manufacture" },
    { title: "Koeponnistusvuosi", value: "year_of_hydrostatic_test" },
    { title: "Nesteen vaihtovuosi", value: "year_of_liquid_replacement" },
    { title: "Tarkastusväli", value: "inspection_frequency" },
    { title: "Viimeisin tarkistus", value: "last_inspection" },
    { title: "Sijainti", value: "placement" },
    { title: "🔗", value: undefined },
  ];

  return (
    <>
      <MainWrapper
        titleText="Sammuttimet"
        addButtonTitle="Lisää uusi sammutin"
        addModalToggle={setShowModal}
      >
        <div className="p-4">
          <div className="flex justify-between">
            <div className="relative mt-1 flex justify-start">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg
                  className="w-5 h-5 text-gray-500 dark:text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </div>
              <input
                type="text"
                id="table-search"
                className="bg-white-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block pl-10 p-2.5"
                placeholder="Hae sammuttimia"
                onChange={debouncedChangeHandler}
              />
              <button className="bg-green-500 hover:bg-green-700 text-white font-bold px-4 border border-green-700 rounded ml-5">
                Skannaa
              </button>
            </div>

            <div>
              <div className="flex justify-between">
                <div>
                  <PageSizeSelect
                    pageSize={resultsPerPageExtinguishers}
                    setPageSize={setResultsPerPageExtinguishers}
                  />
                </div>
              </div>
              <div>
                <span className="text-xs">{`Sivu ${page} / ${
                  range[range.length - 1]
                }`}</span>
              </div>
            </div>
          </div>
          <div></div>
          <div></div>
        </div>
        <table className="border-collapse table-auto w-full text-sm">
          <thead>
            <tr>
              {headers.map((header) => (
                <th
                  className={styles.th}
                  key={header.value}
                  onClick={() =>
                    header.value &&
                    setSorter({
                      value: header.value,
                      direction:
                        header.value === sorter.value
                          ? sorter.direction === 1
                            ? 0
                            : 1
                          : 1,
                    })
                  }
                >
                  {`${header.title} ${
                    header.value === sorter.value
                      ? sorter.direction === 0
                        ? "⬇"
                        : "⬆"
                      : ""
                  }`}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="bg-white">
            {slice.map((row: ExtinguisherRow) => (
              <tr key={row.id} className="hover:bg-green-100 cursor-pointer">
                <td className={styles.td}>{row.id}</td>
                <td className={styles.td + " font-bold"}>{row.qr}</td>
                <td className={styles.td}>{row.location}</td>
                <td className={styles.td}>{row.type}</td>
                <td className={styles.td}>{row.brand}</td>
                <td className={styles.td + (!row.model ? " bg-gray-100" : "")}>
                  {row.model ? row.model : row.legacy_model}
                </td>
                <td className={styles.td}>{`${row.size || ""} ${
                  row.unit || ""
                }`}</td>
                <td className={styles.td}>{row.year_of_manufacture}</td>
                <td className={styles.td}>{row.year_of_hydrostatic_test}</td>
                <td
                  className={
                    styles.tdd +
                    (isLiquidFireExtinguisherType(row.type)
                      ? " bg-green-100"
                      : " bg-gray-300 text-gray-300")
                  }
                >
                  {+row.year_of_liquid_replacement > 0
                    ? row.year_of_liquid_replacement
                    : "-"}
                </td>
                <td className={styles.td}>{row.inspection_frequency}</td>
                <td className={styles.td}>{row.last_inspection_date}</td>
                <td className={styles.td}>{row.placement}</td>
                <td className={styles.td}>
                  <td>
                    {row.location_id && (
                      <ThreePartActionButton
                        row={row}
                        locationId={row.location_id}
                        refreshAction={fetchAllExtinguishers}
                      />
                    )}
                  </td>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </MainWrapper>
      <PageList pageSetter={setPage} page={page} range={range} />
      {showModal ? <AddExtinguisherModal showAction={setShowModal} /> : null}
    </>
  );
};
