import React, { SetStateAction, useEffect, useState, Dispatch } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { api } from "../../api/axiosCalls";
import { CustomerRow } from "./CustomersView";
import { LocationRow } from "../locations/LocationsView";
import { ConfirmModal } from "../../components/common/ConfirmModal";

const styles = {
  inputEdit:
    "appearance-none block w-full bg-red-100 text-gray-700 border border-gray-700 rounded py-1 px-1 mb-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-sm",
};

const ValueDisplay: React.FunctionComponent<{
  title: string;
  value: string;
  changesDone: boolean;
  keyName: string;
  editHandler: (value: string, key: string) => void;
  parentChangeNotifier: Dispatch<SetStateAction<boolean>>;
  highlighted?: boolean;
}> = ({
  title,
  value,
  changesDone,
  parentChangeNotifier,
  editHandler,
  keyName,
  highlighted,
}) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);

  useEffect(() => {
    !changesDone && isEditing && setIsEditing(false);
  }, [changesDone]);

  return (
    <>
      <h4 className="font-bold mt-3">
        <span>{title}</span>
      </h4>
      {isEditing ? (
        <>
          <input
            className={`${styles.inputEdit} ${highlighted && "bg-pink-100"}`}
            type="text"
            defaultValue={value}
            onChange={(e) => {
              parentChangeNotifier(true);
              editHandler(e.target.value, keyName);
            }}
          />
          {highlighted && (
            <span className="text-pink-700 ml-2">*pakollinen</span>
          )}
        </>
      ) : (
        <h1
          className="w-full h-6 cursor-pointer"
          onClick={() => {
            setIsEditing(true);
          }}
        >
          {value}
        </h1>
      )}
    </>
  );
};

const ToggleDisplay: React.FunctionComponent<{
  title: string;
  value: string | number;
  changesDone: boolean;
  keyName: string;
  editHandler: (value: string, key: string) => void;
  parentChangeNotifier: Dispatch<SetStateAction<boolean>>;
}> = ({ title, value, changesDone, editHandler, parentChangeNotifier }) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);

  useEffect(() => {
    !changesDone && isEditing && setIsEditing(false);
  }, [changesDone]);

  return (
    <>
      <h4 className="font-bold mt-3">{title}</h4>
      <input
        className={"mr-2 leading-tight"}
        type="checkbox"
        defaultChecked={value === "1" || value === 1}
        onChange={(e) => {
          parentChangeNotifier(true);
          editHandler(e.target.checked === true ? "1" : "0", "active");
        }}
      />
    </>
  );
};

export const SingleCustomerView: React.FunctionComponent<{}> = () => {
  const navigate = useNavigate();

  const { id } = useParams();

  const query = () => {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  };

  const origin = query().get("origin") || null;

  const [customer, setCustomer] = useState<CustomerRow | null>(null);
  const [locations, setLocations] = useState<LocationRow[] | null>(null);

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const [changesDone, setChangesDone] = useState(false);

  const [customerEdits, setCustomerEdits] = useState<CustomerRow | {}>({});

  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  const deleteCustomer = async () => {
    const deleteCustomerResponse = await api.delete("/customers/delete", {
      data: { id: id },
    });
    if (deleteCustomerResponse.status === 200) {
      navigate("/customers", { replace: true });
    } else {
      // error handling
    }
  };

  let schema = yup.object().shape({
    name: yup.string().required(),
  });

  const fetchCustomer = async () => {
    const customerResponse = await api.post("/customers/read", {
      id,
    });
    if (customerResponse.status === 200) {
      const { data } = customerResponse;
      setCustomer(data);
    }
  };

  const fetchLocations = async () => {
    const eventsResponse = await api.post("/locations/readallforcustomer", {
      id,
    });
    if (eventsResponse.status === 200) {
      const { data } = eventsResponse;
      setLocations(data);
    }
  };

  useEffect(() => {
    fetchCustomer();
    fetchLocations();
  }, []);

  const editCustomer = async () => {
    try {
      "name" in customerEdits &&
        (await schema.validate(customerEdits, { abortEarly: false }));
      const editLocationResponse = await api.put("/customers/update", {
        customer: customerEdits,
        id,
      });
      if (editLocationResponse.status === 200) {
        setChangesDone(false);
        fetchCustomer();
      } else {
        // error handling
      }
    } catch (e) {
      setValidationErrors((e as any).inner.map((item: any) => item.path));
    }
  };

  const customerEditHandler = (value: string, key: string) => {
    setCustomerEdits({ ...customerEdits, [key]: value });
  };

  const styles = {
    th: "border-b font-medium p-1 pl-1 pt-0 pb-2 text-gray-800 text-left text-xs",
    td: "border-b border-slate-300 p-1 pl-1 text-slate-800 text-xs",
    tdd: "border-b p-2 pl-2 text-slate-800 text-xs",
    labelDefault:
      "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2",
    inputDefault:
      "appearance-none block w-full bg-white text-gray-700 border border-gray-400 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500",
  };

  const headers = [
    "Nimi",
    "Katu",
    "Postinumero",
    "Kaupunki",
    "Yhteyshenkilö",
    "Puhelinnumero",
    "Aktiivinen",
    "Lisätiedot",
  ];

  return (
    <>
      {customer ? (
        <div className="shadow-sm overflow-hidden my-8">
          <main className="pt-8 mx-5">
            <div className="flex justify-between">
              <h4 className="text-black font-bold text-4xl">
                Asiakas: {customer.name || ""}
              </h4>
              <div>
                <button
                  className="bg-red-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded my-2 text-sm"
                  onClick={() => setShowDeleteConfirm(true)}
                >
                  Poista asiakas
                </button>
              </div>
            </div>
            <div className="mt-4 -mb-3">
              <div className="not-prose relative bg-slate-100 rounded-xl overflow-x-auto">
                <div className="relative rounded-xl overflow-auto">
                  <div className="shadow-sm overflow-hidden my-8">
                    <div className="flex px-5 justify-between">
                      <div className="flex flex-col md:w-3/12">
                        <ValueDisplay
                          title={"Nimi"}
                          value={customer.name}
                          keyName="name"
                          editHandler={customerEditHandler}
                          changesDone={changesDone}
                          parentChangeNotifier={setChangesDone}
                          highlighted={validationErrors.includes("name")}
                        />
                        <ValueDisplay
                          title={"Katuosoite"}
                          value={customer.street}
                          keyName="street"
                          editHandler={customerEditHandler}
                          changesDone={changesDone}
                          parentChangeNotifier={setChangesDone}
                        />
                        <ValueDisplay
                          title={"Postinumero"}
                          value={customer.zip}
                          keyName="zip"
                          editHandler={customerEditHandler}
                          changesDone={changesDone}
                          parentChangeNotifier={setChangesDone}
                        />
                        <ValueDisplay
                          title={"Postitoimipaikka"}
                          value={customer.city}
                          keyName="city"
                          editHandler={customerEditHandler}
                          changesDone={changesDone}
                          parentChangeNotifier={setChangesDone}
                        />
                        <ValueDisplay
                          title={"Yhteyshenkilö"}
                          value={customer.contact_person}
                          keyName="contactPerson"
                          editHandler={customerEditHandler}
                          changesDone={changesDone}
                          parentChangeNotifier={setChangesDone}
                        />
                        <ValueDisplay
                          title={"Puhelin 1"}
                          value={customer.phone1}
                          keyName="phone1"
                          editHandler={customerEditHandler}
                          changesDone={changesDone}
                          parentChangeNotifier={setChangesDone}
                        />
                        <ValueDisplay
                          title={"Puhelin 2"}
                          value={customer.phone2}
                          keyName="phone2"
                          editHandler={customerEditHandler}
                          changesDone={changesDone}
                          parentChangeNotifier={setChangesDone}
                        />
                        <ValueDisplay
                          title={"Huomiot"}
                          value={customer.notes}
                          keyName="notes"
                          editHandler={customerEditHandler}
                          changesDone={changesDone}
                          parentChangeNotifier={setChangesDone}
                        />
                        <ToggleDisplay
                          title={"Aktiivinen"}
                          value={customer.active}
                          keyName="active"
                          editHandler={customerEditHandler}
                          changesDone={changesDone}
                          parentChangeNotifier={setChangesDone}
                        />

                        <div className="flex">
                          <div>
                            <button
                              onClick={() =>
                                navigate(origin ? origin : "/customers")
                              }
                              className="bg-slate-400 hover:bg-slate-700 text-white font-bold py-2 px-4 rounded my-2 text-sm mr-5"
                            >
                              Takaisin
                            </button>
                          </div>
                          {changesDone && (
                            <>
                              <div>
                                <button
                                  onClick={() => editCustomer()}
                                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded my-2 text-sm mr-5"
                                >
                                  Tallenna muutokset
                                </button>
                              </div>
                              <div>
                                <button
                                  onClick={() => setChangesDone(false)}
                                  className="bg-red-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded my-2 text-sm"
                                >
                                  Peruuta muutokset
                                </button>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="flex flex-col md:w-8/12">
                        <h4 className="text-black font-bold text-2xl mb-10">
                          Asiakkaan kohteet
                        </h4>

                        <table className="border-collapse table-auto w-full text-sm">
                          <thead>
                            <tr>
                              {headers.map((headerName) => (
                                <th className={styles.th} key={headerName}>
                                  {headerName}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody className="bg-white">
                            {locations &&
                              locations.length > 0 &&
                              locations.map((row) => (
                                <tr
                                  key={row.id}
                                  onClick={() =>
                                    navigate(
                                      `/locations/${row.id}?origin=/customers/${id}`
                                    )
                                  }
                                  className="hover:bg-green-100 cursor-pointer"
                                >
                                  <td className={styles.td + " font-bold"}>
                                    {row.name}
                                  </td>
                                  <td className={styles.td}>{row.address}</td>
                                  <td className={styles.td}>{row.zip}</td>
                                  <td className={styles.td}>{row.city}</td>
                                  <td className={styles.td}>
                                    {row.contact_person}
                                  </td>
                                  <td className={styles.td}>
                                    {row.phone_number}
                                  </td>
                                  <td className={styles.td}>{row.active}</td>
                                  <td className={styles.td}>
                                    {row.extra_information}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      ) : null}
      {showDeleteConfirm ? (
        <ConfirmModal
          showAction={setShowDeleteConfirm}
          header="Asiakkaan poisto"
          icon="danger"
          message="Oletko varma että haluat poistaa tämän asiakkaan?"
          confirmAction={() => deleteCustomer()}
        />
      ) : null}
    </>
  );
};
