import React, { useEffect, useState } from "react";

import fi from "date-fns/locale/fi";
import DatePicker, { registerLocale } from "react-datepicker";
import { api } from "../../api/axiosCalls";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";

import Select from "react-select";
import { CustomerRow } from "./CustomersView";

registerLocale("fi", fi);
interface AddExtinguisherFormProps {
  showAction: (show: boolean) => void;
}

const styles = {
  labelDefault:
    "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2",
  inputDefault:
    "appearance-none block w-full bg-white text-gray-700 border border-gray-400 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500",
};

export const AddCustomersModal: React.FunctionComponent<
  AddExtinguisherFormProps
> = ({ showAction }) => {
  const [formData, setFormData] = useState({});
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  let schema = yup.object().shape({
    name: yup.string().required(),
  });

  const navigate = useNavigate();

  const handleEdit = (value: string, field: string) => {
    setFormData({ ...formData, [field]: value });
  };

  const handleCheckbox = (check: boolean, field: string) => {
    setFormData({ ...formData, [field]: check });
  };

  const createCustomer = async () => {
    try {
      await schema.validate(formData, { abortEarly: true });
      const createCustomerResponse = await api.put("/customers/create", {
        customer: formData,
      });
      if (createCustomerResponse.status === 200) {
        navigate(`/customers/${createCustomerResponse.data.id}`);
      }
    } catch (e) {
      console.log(e);
      setValidationErrors((e as any).inner.map((item: any) => item.path));
    }
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-3xl font-semibold">
                Uusi asiakas (🔥 = pakollinen)
              </h3>
              <button
                className="p-1 ml-auto border-0 float-right text-3xl leading-none"
                onClick={() => showAction(false)}
              >
                <span className="text-black h-6 w-6 text-lg block">❌</span>
              </button>
            </div>
            {/*body*/}
            <div className="relative p-6 flex-auto">
              <form className="w-full max-w-lg">
                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full px-3">
                    <label
                      className={styles.labelDefault}
                      htmlFor="grid-password"
                    >
                      Nimi 🔥
                    </label>
                    <input
                      className={styles.inputDefault}
                      type="input"
                      onChange={(e) => {
                        handleEdit(e.target.value, "name");
                      }}
                    />
                    {validationErrors.includes("name") && (
                      <div className="ml-1 mt-1 text-pink-700">
                        Nimi vaadittu
                      </div>
                    )}
                  </div>
                  <div className="w-full px-3">
                    <label
                      className={styles.labelDefault}
                      htmlFor="grid-password"
                    >
                      Katuosoite
                    </label>
                    <input
                      className={styles.inputDefault}
                      type="input"
                      onChange={(e) => {
                        handleEdit(e.target.value, "street");
                      }}
                    />
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-2">
                  <div className="w-full md:w-6/12 px-3 mb-6 md:mb-0">
                    <label className={styles.labelDefault}>Postinumero</label>
                    <input
                      className={styles.inputDefault}
                      type="input"
                      onChange={(e) => {
                        handleEdit(e.target.value, "zip");
                      }}
                    />
                  </div>
                  <div className="w-full md:w-6/12 px-3 mb-6 md:mb-0">
                    <label className={styles.labelDefault}>
                      Postitoimipaikka
                    </label>
                    <input
                      className={styles.inputDefault}
                      type="input"
                      onChange={(e) => {
                        handleEdit(e.target.value, "city");
                      }}
                    />
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-2">
                  <div className="w-full md:w-12/12 px-3 mb-6 md:mb-0">
                    <label className={styles.labelDefault}>Yhteyshenkilö</label>
                    <input
                      className={styles.inputDefault}
                      type="input"
                      onChange={(e) => {
                        handleEdit(e.target.value, "contact_person");
                      }}
                    />
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full md:w-6/12 px-3 mb-6 md:mb-0">
                    <label className={styles.labelDefault}>Puhelin 1</label>
                    <input
                      className={styles.inputDefault}
                      type="input"
                      onChange={(e) => {
                        handleEdit(e.target.value, "phone1");
                      }}
                    />
                  </div>
                  <div className="w-full md:w-6/12 px-3 mb-6 md:mb-0">
                    <label className={styles.labelDefault}>Puhelin 2</label>
                    <input
                      className={styles.inputDefault}
                      type="input"
                      onChange={(e) => {
                        handleEdit(e.target.value, "phone2");
                      }}
                    />
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full md:w-12/12 px-3 mb-6 md:mb-0">
                    <label className={styles.labelDefault}>Huomiot</label>
                    <textarea
                      className={styles.inputDefault}
                      onChange={(e) => {
                        handleEdit(e.target.value, "notes");
                      }}
                    />
                  </div>
                </div>
                <div className="md:flex my-6">
                  <label className="md:w-2/3 block text-gray-700 font-bold">
                    <input
                      className="mr-2 leading-tight"
                      type="checkbox"
                      onChange={(e) => {
                        handleCheckbox(e.target.checked, "active");
                      }}
                    />
                    <span className="text-sm">Aktiivinen</span>
                  </label>
                </div>
              </form>
            </div>
            {/*footer*/}
            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => showAction(false)}
              >
                Sulje
              </button>
              <button
                className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => createCustomer()}
              >
                Tallenna muutokset
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};
