import React, { useEffect, useState } from "react";

import { api } from "../../api/axiosCalls";
import * as yup from "yup";
import {
  BrandRow,
  ModelEdit,
  SizeEdit,
  SizeRow,
  GenericEdit,
  TypeRow,
  TypeEdit,
} from "./ExtinguishersAdminView";
import Select from "react-select";

interface ModalFormProps {
  showAction: (show: boolean) => void;
  updateList: () => void;
  data: GenericEdit | null;
}

interface TypeModalFormProps {
  showAction: (show: boolean) => void;
  updateList: () => void;
  data: TypeEdit | null;
}

interface ModelModalFormProps {
  showAction: (show: boolean) => void;
  updateList: () => void;
  data: ModelEdit | null;
  brands: BrandRow[];
  types: TypeRow[];
  sizes: SizeRow[];
}

interface SizeModalFormProps {
  showAction: (show: boolean) => void;
  updateList: () => void;
  data: SizeEdit | null;
  types: TypeRow[];
}

const styles = {
  labelDefault:
    "block uppercase tracking-wide text-gray-700 text-xs font-bold my-2",
  inputDefault:
    "appearance-none block w-full bg-white text-gray-700 border border-gray-400 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500",
};

export const AddEditTypeModal: React.FunctionComponent<TypeModalFormProps> = ({
  showAction,
  updateList,
  data,
}) => {
  const [formData, setFormData] = useState({});
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  const handleEdit = (value: string, field: string) => {
    setFormData({ ...formData, [field]: value });
  };

  useEffect(() => {
    setFormData({ ...formData, type: data?.value, isLiquid: data?.isLiquid });
  }, [data]);

  let schema = yup.object().shape({
    type: yup.string().required(),
  });

  const createType = async () => {
    try {
      await schema.validate(formData, { abortEarly: false });
      const createTypeResponse = await api.put("/extinguishers/createtype", {
        type: formData,
      });
      if (createTypeResponse.status === 200) {
        showAction(false);
        updateList();
      }
    } catch (e) {
      setValidationErrors((e as any).inner.map((item: any) => item.path));
    }
  };

  const editType = async () => {
    try {
      await schema.validate(formData, { abortEarly: false });
      const editTypeResponse = await api.put("/extinguishers/updatetype", {
        type: formData,
        id: data?.id,
      });
      if (editTypeResponse.status === 200) {
        showAction(false);
        updateList();
      } else {
        // error handling
      }
    } catch (e) {
      setValidationErrors((e as any).inner.map((item: any) => item.path));
    }
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-2xl font-semibold pr-10">{`${
                data !== null
                  ? "Muokkaa sammutintyyppiä"
                  : "Luo uusi sammutintyyppi"
              }`}</h3>
              <button
                className="p-1 ml-auto border-0 float-right text-3xl leading-none"
                onClick={() => showAction(false)}
              >
                <span className="text-black h-6 w-6 text-lg block">❌</span>
              </button>
            </div>
            {/*body*/}
            <div className="relative p-6 flex-auto bg-slate-200">
              <form className="w-full max-w-lg">
                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full px-3">
                    <label
                      className={styles.labelDefault}
                      htmlFor="grid-password"
                    >
                      Tyyppi
                    </label>
                    <input
                      className={styles.inputDefault}
                      type="input"
                      defaultValue={data?.value || ""}
                      onChange={(e) => {
                        handleEdit(e.target.value, "type");
                      }}
                    />
                    {validationErrors.includes("type") && (
                      <div className="ml-1 mt-1 text-pink-700">
                        tyyppi vaadittu
                      </div>
                    )}
                  </div>
                  <div className="w-full px-3">
                    <label
                      className={styles.labelDefault}
                      htmlFor="grid-password"
                    >
                      Neste
                    </label>

                    <input
                      type="checkbox"
                      defaultChecked={data?.isLiquid ? true : false}
                      onChange={(e) => {
                        handleEdit(e.target.checked ? "1" : "0", "isLiquid");
                      }}
                      className="mr-2 leading-tight"
                    />
                  </div>
                  <div className="w-full px-3">
                    <label
                      className={styles.labelDefault}
                      htmlFor="grid-password"
                    >
                      Jauhe
                    </label>

                    <input
                      type="checkbox"
                      defaultChecked={data?.isPowder ? true : false}
                      onChange={(e) => {
                        handleEdit(e.target.checked ? "1" : "0", "isPowder");
                      }}
                      className="mr-2 leading-tight"
                    />
                  </div>
                  <div className="w-full px-3">
                    <label
                      className={styles.labelDefault}
                      htmlFor="grid-password"
                    >
                      CO2
                    </label>

                    <input
                      type="checkbox"
                      defaultChecked={data?.isCO2 ? true : false}
                      onChange={(e) => {
                        handleEdit(e.target.checked ? "1" : "0", "isCO2");
                      }}
                      className="mr-2 leading-tight"
                    />
                  </div>
                  <div className="w-full px-3">
                    <label
                      className={styles.labelDefault}
                      htmlFor="grid-password"
                    >
                      PPP
                    </label>

                    <input
                      type="checkbox"
                      defaultChecked={data?.isPPP ? true : false}
                      onChange={(e) => {
                        handleEdit(e.target.checked ? "1" : "0", "isPPP");
                      }}
                      className="mr-2 leading-tight"
                    />
                  </div>
                </div>
              </form>
            </div>
            {/*footer*/}
            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => showAction(false)}
              >
                Sulje
              </button>
              <button
                className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => (data ? editType() : createType())}
              >
                Tallenna muutokset
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export const AddEditBrandModal: React.FunctionComponent<ModalFormProps> = ({
  showAction,
  updateList,
  data,
}) => {
  const [formData, setFormData] = useState({ brand: data?.value || undefined });
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  const handleEdit = (value: string, field: string) => {
    setFormData({ ...formData, [field]: value });
  };

  let schema = yup.object().shape({
    brand: yup.string().required(),
  });

  const createBrand = async () => {
    try {
      await schema.validate(formData, { abortEarly: false });
      const createBrandResponse = await api.put("/extinguishers/createbrand", {
        brand: formData,
      });
      if (createBrandResponse.status === 200) {
        showAction(false);
        updateList();
      }
    } catch (e) {
      setValidationErrors((e as any).inner.map((item: any) => item.path));
    }
  };

  const editBrand = async () => {
    try {
      await schema.validate(formData, { abortEarly: false });
      const editBrandResponse = await api.put("/extinguishers/updatebrand", {
        brand: formData,
        id: data?.id,
      });
      if (editBrandResponse.status === 200) {
        showAction(false);
        updateList();
      } else {
        // error handling
      }
    } catch (e) {
      setValidationErrors((e as any).inner.map((item: any) => item.path));
    }
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-2xl font-semibold pr-10">{`${
                data !== null ? "Muokkaa merkkiä" : "Luo uusi merkki"
              }`}</h3>
              <button
                className="p-1 ml-auto border-0 float-right text-3xl leading-none"
                onClick={() => showAction(false)}
              >
                <span className="text-black h-6 w-6 text-lg block">❌</span>
              </button>
            </div>
            {/*body*/}
            <div className="relative p-6 flex-auto bg-slate-200">
              <form className="w-full max-w-lg">
                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full px-3">
                    <label
                      className={styles.labelDefault}
                      htmlFor="grid-password"
                    >
                      Merkki
                    </label>
                    <input
                      className={styles.inputDefault}
                      type="input"
                      defaultValue={data?.value || ""}
                      onChange={(e) => {
                        handleEdit(e.target.value, "brand");
                      }}
                    />
                    {validationErrors.includes("brand") && (
                      <div className="ml-1 mt-1 text-pink-700">
                        merkki vaadittu
                      </div>
                    )}
                  </div>
                </div>
              </form>
            </div>
            {/*footer*/}
            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => showAction(false)}
              >
                Sulje
              </button>
              <button
                className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => (data ? editBrand() : createBrand())}
              >
                Tallenna muutokset
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export const AddEditModelModal: React.FunctionComponent<
  ModelModalFormProps
> = ({ showAction, updateList, data, types, brands, sizes }) => {
  const [formData, setFormData] = useState({
    model: data?.model,
    size: data?.size,
    brand: data?.brand,
    type: data?.type,
    alwaysAvailable: data?.always_available,
  });

  const [allowedSizes, setAllowedSizes] = useState<SizeRow[]>([]);

  useEffect(() => {
    formData.type &&
      setAllowedSizes(sizes.filter((size) => size.type == formData.type));
  }, [formData.type]);

  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  const handleEdit = (value: string, field: string) => {
    setFormData({ ...formData, [field]: value });
  };

  let schema = yup.object().shape({
    model: yup.string().required(),
    brand: yup.string().required(),
    type: yup.string().required(),
    size: yup.string().required(),
  });

  let lightSchema = yup.object().shape({
    model: yup.string().required(),
  });

  const createModel = async () => {
    try {
      formData.alwaysAvailable == "1"
        ? await lightSchema.validate(formData, { abortEarly: false })
        : await schema.validate(formData, { abortEarly: false });
      const createModelResponse = await api.put("/extinguishers/createmodel", {
        model: formData,
        alwaysAvailable: formData.alwaysAvailable,
      });
      if (createModelResponse.status === 200) {
        showAction(false);
        updateList();
      }
    } catch (e) {
      console.log(e);
      setValidationErrors((e as any).inner.map((item: any) => item.path));
    }
  };

  const editModel = async () => {
    try {
      formData.alwaysAvailable == "1"
        ? await lightSchema.validate(formData, { abortEarly: false })
        : await schema.validate(formData, { abortEarly: false });
      const editModelResponse = await api.put("/extinguishers/updatemodel", {
        model: formData,
        id: data?.id,
        alwaysAvailable: formData.alwaysAvailable,
      });
      if (editModelResponse.status === 200) {
        showAction(false);
        updateList();
      } else {
        // error handling
      }
    } catch (e) {
      console.log(e);
      setValidationErrors((e as any).inner.map((item: any) => item.path));
    }
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-2xl font-semibold pr-10">{`${
                data !== null ? "Muokkaa mallia" : "Luo uusi malli"
              }`}</h3>
              <button
                className="p-1 ml-auto border-0 float-right text-3xl leading-none"
                onClick={() => showAction(false)}
              >
                <span className="text-black h-6 w-6 text-lg block">❌</span>
              </button>
            </div>
            {/*body*/}
            <div className="relative p-6 flex-auto bg-slate-200">
              <form className="w-full max-w-lg">
                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full px-3">
                    <label
                      className={styles.labelDefault}
                      htmlFor="grid-password"
                    >
                      Malli
                    </label>
                    <input
                      className={styles.inputDefault}
                      type="input"
                      defaultValue={data?.model || ""}
                      onChange={(e) => {
                        handleEdit(e.target.value, "model");
                      }}
                    />
                    {validationErrors.includes("model") && (
                      <div className="ml-1 mt-1 text-pink-700">
                        malli vaadittu
                      </div>
                    )}
                  </div>

                  {formData.alwaysAvailable == "0" ||
                  !formData.alwaysAvailable ? (
                    <>
                      <div className="w-full px-3">
                        <label
                          className={styles.labelDefault}
                          htmlFor="grid-password"
                        >
                          Merkki
                        </label>
                        <Select
                          options={brands}
                          getOptionValue={(o) => o.id}
                          getOptionLabel={(o) => o.brand}
                          defaultValue={brands.find((o) => o.id == data?.brand)}
                          placeholder=""
                          onChange={(e) => {
                            e && handleEdit(e.id, "brand");
                          }}
                        />
                        {validationErrors.includes("brand") && (
                          <div className="ml-1 mt-1 text-pink-700">
                            merkki vaadittu
                          </div>
                        )}
                      </div>
                      <div className="w-full px-3">
                        <label
                          className={styles.labelDefault}
                          htmlFor="grid-password"
                        >
                          Tyyppi
                        </label>
                        <Select
                          options={types}
                          getOptionValue={(o) => o.id}
                          getOptionLabel={(o) => o.type}
                          defaultValue={types.find((o) => o.id == data?.type)}
                          placeholder=""
                          onChange={(e) => {
                            e && handleEdit(e.id, "type");
                          }}
                        />
                        {validationErrors.includes("type") && (
                          <div className="ml-1 mt-1 text-pink-700">
                            tyyppi vaadittu
                          </div>
                        )}
                      </div>
                      <div className="w-full px-3">
                        <label
                          className={styles.labelDefault}
                          htmlFor="grid-password"
                        >
                          Koko
                        </label>
                        <Select
                          options={allowedSizes}
                          getOptionValue={(o) => o.id}
                          getOptionLabel={(o) => `${o.size} ${o.unit}`}
                          defaultValue={sizes.find((o) => o.id == data?.size)}
                          placeholder=""
                          isDisabled={!formData?.type}
                          onChange={(e) => {
                            e && handleEdit(e.id, "size");
                          }}
                        />
                        {validationErrors.includes("size") && (
                          <div className="ml-1 mt-1 text-pink-700">
                            koko vaadittu
                          </div>
                        )}
                      </div>
                    </>
                  ) : null}

                  <div className="w-full px-3">
                    <label className={styles.labelDefault}>
                      Universaali malli
                    </label>

                    <input
                      type="checkbox"
                      defaultChecked={data?.always_available ? true : false}
                      onChange={(e) => {
                        handleEdit(
                          e.target.checked ? "1" : "0",
                          "alwaysAvailable"
                        );
                      }}
                      className="mr-2 leading-tight"
                    />
                  </div>
                </div>
              </form>
            </div>
            {/*footer*/}
            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => showAction(false)}
              >
                Sulje
              </button>
              <button
                className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => (data ? editModel() : createModel())}
              >
                Tallenna muutokset
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export const AddEditSizeModal: React.FunctionComponent<SizeModalFormProps> = ({
  showAction,
  updateList,
  data,
  types,
}) => {
  const [formData, setFormData] = useState({});
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  const handleEdit = (value: string, field: string) => {
    setFormData({ ...formData, [field]: value });
  };

  let schema = yup.object().shape({
    size: yup.string().required(),
    unit: yup.string().required(),
    type: yup.string().required(),
  });

  useEffect(() => {
    setFormData({
      ...formData,
      size: data?.size,
      unit: data?.unit,
      type: data?.type,
    });
  }, [data]);

  const createSize = async () => {
    try {
      await schema.validate(formData, { abortEarly: false });
      const createSizeResponse = await api.put("/extinguishers/createsize", {
        size: formData,
      });
      if (createSizeResponse.status === 200) {
        showAction(false);
        updateList();
      }
    } catch (e) {
      console.log(e);
      setValidationErrors((e as any).inner.map((item: any) => item.path));
    }
  };

  const editSize = async () => {
    await schema.validate(formData, { abortEarly: false });
    const editSizeResponse = await api.put("/extinguishers/updatesize", {
      size: formData,
      id: data?.id,
    });
    if (editSizeResponse.status === 200) {
      showAction(false);
      updateList();
    } else {
      // error handling
    }
  };

  const sizeOptions = [
    { value: "ltr", label: "Litra" },
    { value: "kg", label: "Kilogramma" },
    { value: "mm/m", label: "mm/m" },
  ];

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-2xl font-semibold pr-10">{`${
                data !== null ? "Muokkaa kokoa" : "Luo uusi koko"
              }`}</h3>
              <button
                className="p-1 ml-auto border-0 float-right text-3xl leading-none"
                onClick={() => showAction(false)}
              >
                <span className="text-black h-6 w-6 text-lg block">❌</span>
              </button>
            </div>
            {/*body*/}
            <div className="relative p-6 flex-auto bg-slate-200">
              <form className="w-full max-w-lg">
                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full px-3">
                    <label
                      className={styles.labelDefault}
                      htmlFor="grid-password"
                    >
                      Koko
                    </label>
                    <input
                      className={styles.inputDefault}
                      type="number"
                      min="0"
                      defaultValue={data?.size || ""}
                      onChange={(e) => {
                        handleEdit(e.target.value, "size");
                      }}
                    />
                    {validationErrors.includes("brand") && (
                      <div className="ml-1 mt-1 text-pink-700">
                        koko vaadittu
                      </div>
                    )}
                  </div>
                  <div className="w-full px-3">
                    <label
                      className={styles.labelDefault}
                      htmlFor="grid-password"
                    >
                      Yksikkö
                    </label>
                    <Select
                      options={sizeOptions}
                      placeholder=""
                      defaultValue={sizeOptions.find(
                        (o) => o.value == data?.unit
                      )}
                      onChange={(e) => {
                        e && handleEdit(e.value, "unit");
                      }}
                    />
                    {validationErrors.includes("unit") && (
                      <div className="ml-1 mt-1 text-pink-700">
                        yksikkö vaadittu
                      </div>
                    )}
                  </div>
                  <div className="w-full px-3">
                    <label
                      className={styles.labelDefault}
                      htmlFor="grid-password"
                    >
                      Tyyppi
                    </label>
                    <Select
                      options={types}
                      getOptionValue={(o) => o.id}
                      getOptionLabel={(o) => o.type}
                      defaultValue={types.find((o) => o.id == data?.type)}
                      placeholder=""
                      onChange={(e) => {
                        e && handleEdit(e.id, "type");
                      }}
                    />
                    {validationErrors.includes("type") && (
                      <div className="ml-1 mt-1 text-pink-700">
                        tyyppi vaadittu
                      </div>
                    )}
                  </div>
                </div>
              </form>
            </div>
            {/*footer*/}
            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => showAction(false)}
              >
                Sulje
              </button>
              <button
                className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => (data ? editSize() : createSize())}
              >
                Tallenna muutokset
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};
