import React, { SetStateAction, useEffect, useState, Dispatch } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { format, parseISO } from "date-fns";
import * as yup from "yup";
import { fi } from "date-fns/locale";
import { CSVLink, CSVDownload } from "react-csv";
import { api } from "../../api/axiosCalls";
import { ExtinguisherRow } from "./ExtinguishersView";
import { AccountRow, PerformInspectionModal } from "./PerformInspectionModal";
import { ConfirmModal } from "../../components/common/ConfirmModal";

import { LocationRow } from "../locations/LocationsView";
import { ValueDisplay } from "../../components/selects/ValueDisplay";
import { ValueDisplaySelectLocation } from "../../components/selects/ValueDisplaySelectLocation";
import { BrandRow, ModelRow, SizeRow, TypeRow } from "./ExtinguishersAdminView";
import { ValueDisplaySelectSimple } from "../../components/selects/ValueDisplaySelectSimple";
import { ValueDisplaySelectSize } from "../../components/selects/ValueDisplaySelectSize";
import { EditEventModal } from "./EditEventModal";
import { parseSQLDate } from "../../utils/datef";
import QRCode from "react-qr-code";
import { AddExtinguisherModal } from "./AddExtinguishersModal";

export interface EventRow {
  id: string;
  event_type: string;
  type_id: string;
  first_name: string;
  last_name: string;
  inspector: string;
  inspection_date: string;
  notes: string;
  extinguisher: string;
  location: string;
  location_name: string;
}

export const SingleExtinguisherView: React.FunctionComponent<{}> = () => {
  const navigate = useNavigate();

  const { id } = useParams();

  const query = () => {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  };

  const origin = query().get("origin") || null;

  const [extinguisher, setExtinguisher] = useState<ExtinguisherRow | null>(
    null
  );

  const [qrTaken, setQrTaken] = useState(false);

  const [events, setEvents] = useState<EventRow[] | null>([]);
  const [changesDone, setChangesDone] = useState(false);
  const [showInspectionModal, setShowInspectionModal] = useState(false);
  const [showInspectionEditModal, setShowInspectionEditModal] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [confirmEventDelete, setConfirmDeleteEvent] = useState<string>();

  const [allLocations, setAllLocations] = useState<LocationRow[]>([]);
  const [allTypes, setAllTypes] = useState<TypeRow[]>([]);
  const [allBrands, setAllBrands] = useState<BrandRow[]>([]);
  const [allModels, setAllModels] = useState<ModelRow[]>([]);
  const [accounts, setAccounts] = useState<AccountRow[]>([]);
  const [allSizes, setAllSizes] = useState<SizeRow[]>([]);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [selectedEvent, setSelectedEvent] = useState<EventRow | null>(null);

  const [extinguisherEdits, setExtinguisherEdits] = useState<
    Partial<ExtinguisherRow>
  >({});

  const [allowedSizes, setAllowedSizes] = useState<SizeRow[]>([]);
  const [allowedModels, setAllowedModels] = useState<ModelRow[]>([]);

  const [showExtinguisherCopyModal, setShowExtinguisherCopyModal] =
    useState(false);

  useEffect(() => {
    extinguisherEdits?.type &&
      allSizes &&
      setAllowedSizes(
        allSizes.filter((size) => size.type == extinguisherEdits.type)
      );
  }, [allSizes, extinguisherEdits.type]);

  useEffect(() => {
    extinguisherEdits.type &&
      extinguisherEdits.brand &&
      extinguisherEdits.size &&
      allModels &&
      setAllowedModels(
        allModels.filter(
          (model) =>
            (model.type == extinguisherEdits.type &&
              model.size == extinguisherEdits.size &&
              model.brand == extinguisherEdits.brand) ||
            model.always_available == "1"
        )
      );
  }, [
    allSizes,
    allModels,
    extinguisherEdits.type,
    extinguisherEdits.size,
    extinguisherEdits.brand,
    extinguisherEdits.model,
    extinguisherEdits,
  ]);

  const fetchAllLocations = async () => {
    const allLocationsResponse = await api.get("/locations");
    if (allLocationsResponse.status === 200) {
      const { data } = allLocationsResponse;
      setAllLocations(data);
    }
  };

  const fetchAllTypes = async () => {
    const allTypesResponse = await api.get("/extinguishers/types");
    if (allTypesResponse.status === 200) {
      const { data } = allTypesResponse;
      setAllTypes(data);
    }
  };

  const fetchAllBrands = async () => {
    const allBrandsResponse = await api.get("/extinguishers/brands");
    if (allBrandsResponse.status === 200) {
      const { data } = allBrandsResponse;
      setAllBrands(data);
    }
  };

  const fetchAllModels = async () => {
    const allModelsResponse = await api.get("/extinguishers/models");
    if (allModelsResponse.status === 200) {
      const { data } = allModelsResponse;
      setAllModels(data);
    }
  };

  const fetchAccounts = async () => {
    const accountsResponse = await api.get("/accounts/select");
    if (accountsResponse.status === 200) {
      const { data } = accountsResponse;
      setAccounts(data);
    }
  };

  const fetchAllSizes = async () => {
    const allSizesResponse = await api.get("/extinguishers/sizes");
    if (allSizesResponse.status === 200) {
      const { data } = allSizesResponse;
      setAllSizes(data);
    }
  };

  useEffect(() => {
    fetchAllLocations();
    fetchAllTypes();
    fetchAllBrands();
    fetchAllModels();
    fetchAccounts();
    fetchAllSizes();
  }, []);

  const editExtinguisher = async () => {
    try {
      await schema.validate(extinguisherEdits, { abortEarly: false });
      const editExtinguisherResponse = await api.put("/extinguishers/update", {
        extinguisher: extinguisherEdits,
        id,
      });
      if (editExtinguisherResponse.status === 200) {
        setChangesDone(false);
        setQrTaken(false);
        fetchExtinguisher();
      } else if (editExtinguisherResponse.status === 226) {
        setQrTaken(true);
      }
    } catch (e) {
      console.log(e);
      setValidationErrors((e as any).inner.map((item: any) => item.path));
    }
  };

  const deleteEvent = async (id: string) => {
    try {
      const deleteEventResponse = await api.delete("/events/delete", {
        data: { id },
      });
      if (deleteEventResponse.status === 200) {
        setChangesDone(false);
        setConfirmDeleteEvent("");
        fetchEvents();
      }
    } catch (e) {
      console.log(e);
    }
  };

  let schema = yup.object().shape({
    qr: yup.string().required(),
    location: yup.string().required(),
    placement: yup.string().required(),
    type: yup.string().required(),
    brand: yup.string().required(),
  });

  const extinguisherEditHandler = (value: string, key: string) => {
    setExtinguisherEdits({ ...extinguisherEdits, [key]: value });
  };

  const fetchExtinguisher = async () => {
    const locationResponse = await api.post("/extinguishers/readresolved", {
      id,
    });
    if (locationResponse.status === 200) {
      const { data } = locationResponse;
      setExtinguisher(data);
    }
  };

  const deleteExtinguisher = async () => {
    const deleteExtinguisherResponse = await api.delete(
      "/extinguishers/delete",
      {
        data: { id: id },
      }
    );
    if (deleteExtinguisherResponse.status === 200) {
      await api.delete("/events/deleteallbyextinguisher", {
        data: { id: id },
      });
      navigate("/extinguishers", { replace: true });
    } else {
      // error handling
    }
  };

  const fetchEvents = async () => {
    const eventsResponse = await api.post(
      "/events/readallresolvedforextinguisher",
      {
        id,
      }
    );
    if (eventsResponse.status === 200) {
      const { data } = eventsResponse;
      setEvents(data);
    }
  };

  useEffect(() => {
    !showInspectionModal && !showInspectionEditModal && fetchExtinguisher();
    !showInspectionModal && !showInspectionEditModal && fetchEvents();
  }, [showInspectionModal, showInspectionEditModal, id]);

  useEffect(() => {
    extinguisher &&
      setExtinguisherEdits({
        ...extinguisher,
        location: extinguisher.location_id,
        type:
          allTypes.find((x) => x.type == extinguisher.type)?.id || undefined,
        brand:
          allBrands.find((x) => x.brand == extinguisher.brand)?.id || undefined,
        model:
          allModels.find((x) => x.model == extinguisher.model)?.id || undefined,
        size: allSizes.find((x) => x.id == extinguisher.size)?.id || undefined,
      });
  }, [extinguisher, allTypes, allBrands, allModels, allSizes]);

  const styles = {
    th: "border-b font-medium p-1 pl-1 pt-0 pb-2 text-gray-800 text-left text-xs",
    td: "border-b border-slate-300 p-1 pl-1 text-slate-800 text-xs",
    tdd: "border-b p-2 pl-2 text-slate-800 text-xs",
    labelDefault:
      "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2",
    inputDefault:
      "appearance-none block w-full bg-white text-gray-700 border border-gray-400 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500",
  };

  const headers = [
    "Tapahtuma",
    "Suorittaja",
    "Tapahtumapvm",
    "Kohde",
    "Huom",
    "",
  ];

  const getCsv = () => {
    let outPut = [headers];
    events &&
      events.forEach((row) => {
        outPut.push([
          row.event_type,
          `${row.first_name} ${row.last_name}`,
          format(parseISO(row.inspection_date), "dd/MM/yyyy", { locale: fi }),
          row.notes,
        ]);
      });

    return outPut;
  };

  return (
    <>
      {extinguisher ? (
        <div className="shadow-sm overflow-hidden my-8">
          <main className="pt-8 mx-5">
            <div className="flex justify-between">
              <h4 className="text-black font-bold text-4xl">
                Sammutin: {extinguisher.qr || ""}
              </h4>
              <QRCode value={extinguisher.qr} size={100} />
              <div>
                <button
                  onClick={() => setShowInspectionModal(true)}
                  className="bg-teal-700 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded my-2 text-sm"
                >
                  Suorita tarkastus
                </button>
              </div>
              <div>
                <button
                  onClick={() => setShowExtinguisherCopyModal(true)}
                  className="bg-blue-700 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded my-2 text-sm"
                >
                  Kopioi sammutin
                </button>
              </div>
              <div>
                <button
                  className="bg-red-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded my-2 text-sm"
                  onClick={() => setShowDeleteConfirm(true)}
                >
                  Poista sammutin
                </button>
              </div>
            </div>
            <div className="mt-4 -mb-3">
              <div className="not-prose relative bg-slate-100 rounded-xl overflow-x-auto">
                <div className="relative rounded-xl overflow-auto">
                  <div className="shadow-sm overflow-hidden my-8">
                    <div className="flex px-5 justify-between">
                      <div className="flex flex-col md:w-3/12">
                        <ValueDisplay
                          title={"Koodi"}
                          value={extinguisher.qr}
                          keyName="qr"
                          editHandler={extinguisherEditHandler}
                          changesDone={changesDone}
                          parentChangeNotifier={setChangesDone}
                        />
                        {validationErrors.includes("qr") && (
                          <div className="ml-1 mt-1 text-pink-700">
                            Koodi vaadittu
                          </div>
                        )}
                        {qrTaken && (
                          <div className="ml-1 mt-1 text-pink-700">
                            Koodi käytössä!
                          </div>
                        )}
                        <ValueDisplaySelectLocation
                          title={"Kohde"}
                          value={extinguisher.location}
                          keyName="location_id"
                          editHandler={extinguisherEditHandler}
                          changesDone={changesDone}
                          parentChangeNotifier={setChangesDone}
                          allLocations={allLocations}
                        />
                        <ValueDisplaySelectSimple
                          title={"Tyyppi"}
                          value={extinguisher.type}
                          keyName="type"
                          editHandler={extinguisherEditHandler}
                          changesDone={changesDone}
                          parentChangeNotifier={setChangesDone}
                          data={allTypes}
                          valueName="type"
                        />
                        <ValueDisplaySelectSimple
                          title={"Merkki"}
                          value={extinguisher.brand}
                          keyName="brand"
                          editHandler={extinguisherEditHandler}
                          changesDone={changesDone}
                          parentChangeNotifier={setChangesDone}
                          data={allBrands}
                          valueName="brand"
                        />
                        <ValueDisplaySelectSize
                          title={"Koko"}
                          value={extinguisher.size_id}
                          keyName="size"
                          editHandler={extinguisherEditHandler}
                          changesDone={changesDone}
                          parentChangeNotifier={setChangesDone}
                          data={allowedSizes}
                        />
                        <ValueDisplaySelectSimple
                          title={"Malli"}
                          value={
                            extinguisher.model || extinguisher.legacy_model
                          }
                          keyName="model"
                          editHandler={extinguisherEditHandler}
                          changesDone={changesDone}
                          parentChangeNotifier={setChangesDone}
                          data={allowedModels}
                          valueName="model"
                        />

                        <ValueDisplay
                          title={"Valmistusvuosi"}
                          value={extinguisher.year_of_manufacture}
                          keyName="yearOfManufacture"
                          editHandler={extinguisherEditHandler}
                          changesDone={changesDone}
                          parentChangeNotifier={setChangesDone}
                        />
                        <ValueDisplay
                          title={"Koeponnistusvuosi"}
                          value={extinguisher.year_of_hydrostatic_test}
                          keyName="yearOfHydrostaticTest"
                          editHandler={extinguisherEditHandler}
                          changesDone={changesDone}
                          parentChangeNotifier={setChangesDone}
                        />
                        <ValueDisplay
                          title={"Nesteen vaihtovuosi"}
                          value={extinguisher.year_of_liquid_replacement}
                          keyName="yearOfLiquidReplacement"
                          editHandler={extinguisherEditHandler}
                          changesDone={changesDone}
                          parentChangeNotifier={setChangesDone}
                        />
                        <ValueDisplay
                          title={"Tarkastusväli"}
                          value={extinguisher.inspection_frequency}
                          keyName="inspectionFrequency"
                          editHandler={extinguisherEditHandler}
                          changesDone={changesDone}
                          parentChangeNotifier={setChangesDone}
                        />
                        <ValueDisplay
                          title={"Viimeisin tarkistus"}
                          value={
                            extinguisher.last_inspection_date &&
                            format(
                              parseSQLDate(extinguisher.last_inspection_date),
                              "dd/MM/yyyy"
                            )
                          }
                          keyName="last_inspection_date"
                          editHandler={extinguisherEditHandler}
                          changesDone={changesDone}
                          parentChangeNotifier={setChangesDone}
                        />

                        <ValueDisplay
                          title={"Sijainti"}
                          value={extinguisher.placement}
                          keyName="placement"
                          editHandler={extinguisherEditHandler}
                          changesDone={changesDone}
                          parentChangeNotifier={setChangesDone}
                        />
                        <ValueDisplay
                          title={"Tila"}
                          value={events?.[0]?.event_type || ""}
                          keyName="event_type"
                          editHandler={extinguisherEditHandler}
                          changesDone={changesDone}
                          parentChangeNotifier={setChangesDone}
                          readOnly
                        />

                        <div className="flex">
                          <div>
                            <button
                              onClick={() =>
                                navigate(origin ? origin : "/extinguishers")
                              }
                              className="bg-slate-400 hover:bg-slate-700 text-white font-bold py-2 px-4 rounded my-2 text-sm mr-5"
                            >
                              Takaisin
                            </button>
                          </div>
                          {changesDone && (
                            <>
                              <div>
                                <button
                                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded my-2 text-sm mr-5"
                                  onClick={() => editExtinguisher()}
                                >
                                  Tallenna muutokset
                                </button>
                              </div>
                              <div>
                                <button
                                  onClick={() => setChangesDone(false)}
                                  className="bg-red-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded my-2 text-sm"
                                >
                                  Peruuta muutokset
                                </button>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="flex flex-col md:w-8/12">
                        <h4 className="text-black font-bold text-2xl mb-10">
                          Tapahtumat
                          <CSVLink
                            data={getCsv()}
                            className="bg-green-700 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded my-2 text-xs w-24 ml-5"
                            filename={`${extinguisher?.qr}_tapahtumat_${format(
                              new Date(),
                              "dd/MM/yyyy"
                            )}`}
                          >
                            Lataa CSV
                          </CSVLink>
                        </h4>

                        <table className="border-collapse table-auto w-full text-sm">
                          <thead>
                            <tr>
                              {headers.map((headerName) => (
                                <th className={styles.th} key={headerName}>
                                  {headerName}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody className="bg-white">
                            {events &&
                              events.length > 0 &&
                              events.map((row) => (
                                <tr
                                  key={row.id}
                                  className="hover:bg-green-100 cursor-pointer"
                                >
                                  <td className={styles.td + " font-bold"}>
                                    {row.event_type}
                                  </td>
                                  <td className={styles.td + " font-bold"}>
                                    {`${row.first_name} ${row.last_name}`}
                                  </td>
                                  <td className={styles.td + " font-bold"}>
                                    {format(
                                      parseISO(row.inspection_date),
                                      "dd/MM/yyyy"
                                    )}
                                  </td>
                                  <td className={styles.td + " font-bold"}>
                                    {row.location_name}
                                  </td>
                                  <td className={styles.td + " font-bold"}>
                                    {row.notes}
                                  </td>
                                  <td className={styles.td + " font-bold"}>
                                    <button
                                      className="bg-blue-700 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded my-2 text-xs w-24 ml-5"
                                      onClick={() => {
                                        setShowInspectionEditModal(true);
                                        setSelectedEvent(row);
                                      }}
                                    >
                                      Muokkaa
                                    </button>
                                    {confirmEventDelete === row.id ? (
                                      <>
                                        <button
                                          className="bg-red-700 hover:bg-red-500 text-white font-bold py-2 px-4 rounded my-2 text-sm ml-2"
                                          onClick={() => deleteEvent(row.id)}
                                        >
                                          Vahvista poisto
                                        </button>
                                        <button
                                          className="bg-slate-700 hover:bg-slate-500 text-white font-bold py-2 px-4 rounded my-2 text-sm ml-2"
                                          onClick={() =>
                                            setConfirmDeleteEvent("")
                                          }
                                        >
                                          Peruuta
                                        </button>
                                      </>
                                    ) : (
                                      <button
                                        className="bg-red-500 hover:bg-red-400 text-white font-bold py-2 px-4 rounded my-2 text-sm ml-2"
                                        onClick={() =>
                                          setConfirmDeleteEvent(row.id)
                                        }
                                      >
                                        Poista
                                      </button>
                                    )}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {showInspectionModal ? (
              <PerformInspectionModal
                showAction={setShowInspectionModal}
                extinguisher={extinguisher}
              />
            ) : null}
            {showInspectionEditModal && selectedEvent ? (
              <EditEventModal
                showAction={setShowInspectionEditModal}
                event={selectedEvent}
                extinguisher={extinguisher}
                locations={allLocations}
              />
            ) : null}
            {showDeleteConfirm ? (
              <ConfirmModal
                showAction={setShowDeleteConfirm}
                header="Sammuttimen poisto"
                icon="danger"
                message="Oletko varma että haluat poistaa tämän sammuttimen?"
                confirmAction={() => deleteExtinguisher()}
              />
            ) : null}
            {showExtinguisherCopyModal && (
              <AddExtinguisherModal
                showAction={setShowExtinguisherCopyModal}
                data={{ id: extinguisher.id }}
              />
            )}
          </main>
        </div>
      ) : null}
    </>
  );
};
