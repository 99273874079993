import React, { useEffect, useState } from "react";
import { api } from "../../api/axiosCalls";
import { MainWrapper } from "../../components/common/MainWrapper";
import { SubNavigator } from "../../components/common/SubNavigator";
import { ConfirmModal } from "../../components/common/ConfirmModal";
import {
  AddEditBrandModal,
  AddEditModelModal,
  AddEditSizeModal,
  AddEditTypeModal,
} from "./ContentModals";

export interface TypeRow {
  id: string;
  type: string;
  is_liquid: string;
  is_powder: string;
  is_co2: string;
  is_ppp: string;
}

export interface BrandRow {
  id: string;
  brand: string;
}

export interface ModelRow {
  id: string;
  model: string;
  brand: string;
  brand_name: string;
  type: string;
  type_name: string;
  size: string;
  size_name: string;
  always_available: string;
}

export interface SizeRow {
  id: string;
  size: string;
  unit: string;
  type: string;
  type_name: string;
}

export interface GenericEdit {
  id: string;
  value: string;
}

export interface TypeEdit {
  id: string;
  value: string;
  isLiquid: string;
  isPowder: string;
  isCO2: string;
  isPPP: string;
}

export interface BrandEdit {
  id: string;
  value: string;
}

export interface ModelEdit {
  id: string;
  model: string;
  size: string;
  type: string;
  brand: string;
  always_available: string;
}

export interface SizeEdit {
  id: string;
  size: string;
  unit: string;
  type: string;
  type_name: string;
}

const styles = {
  th: "border-b font-medium p-1 pl-1 pt-0 pb-2 text-gray-800 text-left text-xs",
  td: "border-b border-slate-300 p-1 pl-1 text-slate-800 text-xs",
};

export const ExtinguishersAdminView: React.FunctionComponent<{}> = () => {
  const [active, setActive] = useState("types");
  const [allTypes, setAllTypes] = useState<TypeRow[]>([]);
  const [allBrands, setAllBrands] = useState<BrandRow[]>([]);
  const [allModels, setAllModels] = useState<ModelRow[]>([]);
  const [allSizes, setAllSizes] = useState<SizeRow[]>([]);

  const [editedType, setEditedType] = useState<TypeEdit | null>(null);
  const [editedBrand, setEditedBrand] = useState<BrandEdit | null>(null);
  const [editedModel, setEditedModel] = useState<ModelEdit | null>(null);
  const [editedSize, setEditedSize] = useState<SizeEdit | null>(null);

  const [showTypeModal, setShowTypeModal] = useState(false);
  const [showBrandModal, setShowBrandModal] = useState(false);
  const [showModelModal, setShowModelModal] = useState(false);
  const [showSizeModal, setShowSizeModal] = useState(false);

  const [showTypeConfirm, setShowTypeConfirm] = useState(false);
  const [showBrandConfirm, setShowBrandConfirm] = useState(false);
  const [showModelConfirm, setShowModelConfirm] = useState(false);
  const [showSizeConfirm, setShowSizeConfirm] = useState(false);

  const [confirmAction, setConfirmAction] = useState<
    null | (() => Promise<void>)
  >(null);

  const fetchAllTypes = async () => {
    const allTypesResponse = await api.get("/extinguishers/types");
    if (allTypesResponse.status === 200) {
      const { data } = allTypesResponse;
      setAllTypes(data);
    }
  };

  const fetchAllBrands = async () => {
    const allBrandsResponse = await api.get("/extinguishers/brands");
    if (allBrandsResponse.status === 200) {
      const { data } = allBrandsResponse;
      setAllBrands(data);
    }
  };

  const fetchAllModels = async () => {
    const allModelsResponse = await api.get("/extinguishers/models");
    if (allModelsResponse.status === 200) {
      const { data } = allModelsResponse;
      setAllModels(data);
    }
  };

  const fetchAllSizes = async () => {
    const allSizesResponse = await api.get("/extinguishers/sizes");
    if (allSizesResponse.status === 200) {
      const { data } = allSizesResponse;
      setAllSizes(data);
    }
  };

  useEffect(() => {
    fetchAllTypes();
    fetchAllBrands();
    fetchAllModels();
    fetchAllSizes();
  }, []);

  const deleteType = async (id: string) => {
    const finalDelete = async () => {
      const deleteTypeResponse = await api.delete("/extinguishers/deletetype", {
        data: { id },
      });
      if (deleteTypeResponse.status === 200) {
        fetchAllTypes();
      } else {
        // error handling
      }
    };

    setShowTypeConfirm(true);
    setConfirmAction(() => finalDelete);
  };

  const deleteBrand = async (id: string) => {
    const finalDelete = async () => {
      const deleteBrandResponse = await api.delete(
        "/extinguishers/deletebrand",
        {
          data: { id },
        }
      );
      if (deleteBrandResponse.status === 200) {
        fetchAllBrands();
      } else {
        // error handling
      }
    };

    setShowBrandConfirm(true);
    setConfirmAction(() => finalDelete);
  };

  const deleteModel = async (id: string) => {
    const finalDelete = async () => {
      const deleteModelResponse = await api.delete(
        "/extinguishers/deletemodel",
        {
          data: { id },
        }
      );
      if (deleteModelResponse.status === 200) {
        fetchAllModels();
      } else {
        // error handling
      }
    };

    setShowModelConfirm(true);
    setConfirmAction(() => finalDelete);
  };

  const deleteSize = async (id: string) => {
    const finalDelete = async () => {
      const deleteSizeResponse = await api.delete("/extinguishers/deletesize", {
        data: { id },
      });
      if (deleteSizeResponse.status === 200) {
        fetchAllSizes();
      } else {
        // error handling
      }
    };

    setShowSizeConfirm(true);
    setConfirmAction(() => finalDelete);
  };

  return (
    <MainWrapper titleText="Sammuttimien hallinnointi">
      <SubNavigator
        setActive={setActive}
        active={active}
        items={[
          { title: "Tyypit", key: "types" },
          { title: "Merkit", key: "brands" },
          { title: "Mallit", key: "models" },
          { title: "Koot", key: "sizes" },
        ]}
      />
      {/* Tyypit */}
      {active === "types" && (
        <div className="flex items-center flex-col py-5">
          <button
            className="bg-green-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded my-2 mr-2 text-xm w-fit"
            onClick={() => {
              setEditedType(null);
              setShowTypeModal(true);
            }}
          >
            Lisää uusi
          </button>
          <div className="overflow-y-auto" style={{ maxHeight: "60vh" }}>
            <table
              className="table-auto overflow-scroll"
              style={{ minWidth: "500px", width: "60%" }}
            >
              <thead>
                <tr>
                  <th className={styles.th}>ID</th>
                  <th className={styles.th}>Tyyppi</th>
                  <th className={styles.th}>Neste</th>
                  <th className={styles.th}>Jauhe</th>
                  <th className={styles.th}>CO2</th>
                  <th className={styles.th}>PPP</th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {allTypes.map((row: TypeRow) => (
                  <tr key={row.id}>
                    <td className={styles.td + " font-bold"}>{row.id}</td>
                    <td className={styles.td}>{row.type}</td>
                    <td className={styles.td}>
                      <input
                        type="checkbox"
                        checked={row.is_liquid ? true : false}
                        className="mr-2 leading-tight"
                        disabled
                      />
                    </td>
                    <td className={styles.td}>
                      <input
                        type="checkbox"
                        checked={row.is_powder ? true : false}
                        className="mr-2 leading-tight"
                        disabled
                      />
                    </td>
                    <td className={styles.td}>
                      <input
                        type="checkbox"
                        checked={row.is_co2 ? true : false}
                        className="mr-2 leading-tight"
                        disabled
                      />
                    </td>
                    <td className={styles.td}>
                      <input
                        type="checkbox"
                        checked={row.is_ppp ? true : false}
                        className="mr-2 leading-tight"
                        disabled
                      />
                    </td>
                    <td className={styles.td + " text-right"}>
                      <button
                        className="bg-green-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded my-2 mr-2 text-xm"
                        onClick={() => {
                          setEditedType({
                            id: row.id,
                            value: row.type,
                            isLiquid: row.is_liquid,
                            isPowder: row.is_powder,
                            isCO2: row.is_co2,
                            isPPP: row.is_ppp,
                          });
                          setShowTypeModal(true);
                        }}
                      >
                        Muokkaa
                      </button>
                      <button
                        className="bg-red-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded my-2 mr-2 text-xm"
                        onClick={() => deleteType(row.id)}
                      >
                        Poista
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {/* Merkit */}
      {active === "brands" && (
        <div className="flex items-center flex-col py-5">
          <button
            className="bg-green-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded my-2 mr-2 text-xm w-fit"
            onClick={() => {
              setEditedBrand(null);
              setShowBrandModal(true);
            }}
          >
            Lisää uusi
          </button>
          <div className="overflow-y-auto" style={{ maxHeight: "60vh" }}>
            <table
              className="table-auto overflow-scroll"
              style={{ minWidth: "500px", width: "60%" }}
            >
              <thead>
                <tr>
                  <th className={styles.th}>ID</th>
                  <th className={styles.th}>Merkki</th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {allBrands.map((row: BrandRow) => (
                  <tr key={row.id}>
                    <td className={styles.td + " font-bold"}>{row.id}</td>
                    <td className={styles.td}>{row.brand}</td>
                    <td className={styles.td + " text-right"}>
                      <button
                        className="bg-green-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded my-2 mr-2 text-xm"
                        onClick={() => {
                          setEditedBrand({ id: row.id, value: row.brand });
                          setShowBrandModal(true);
                        }}
                      >
                        Muokkaa
                      </button>
                      <button
                        className="bg-red-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded my-2 mr-2 text-xm"
                        onClick={() => deleteBrand(row.id)}
                      >
                        Poista
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {/* Mallit */}
      {active === "models" && (
        <div className="flex items-center flex-col py-5">
          <button
            className="bg-green-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded my-2 mr-2 text-xm w-fit"
            onClick={() => {
              setEditedModel(null);
              setShowModelModal(true);
            }}
          >
            Lisää uusi
          </button>
          <div className="overflow-y-auto" style={{ maxHeight: "60vh" }}>
            <table
              className="table-auto overflow-scroll"
              style={{ minWidth: "500px", width: "60%" }}
            >
              <thead>
                <tr>
                  <th className={styles.th}>ID</th>
                  <th className={styles.th}>Malli</th>
                  <th className={styles.th}>Merkki</th>
                  <th className={styles.th}>Tyyppi</th>
                  <th className={styles.th}>Koko</th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {allModels.map((row: ModelRow) => (
                  <tr key={row.id}>
                    <td className={styles.td + " font-bold"}>{row.id}</td>
                    <td className={styles.td}>{row.model}</td>
                    <td className={styles.td}>{row.brand_name}</td>
                    <td className={styles.td}>{row.type_name}</td>
                    <td className={styles.td}>{row.size_name}</td>
                    <td className={styles.td + " text-right"}>
                      <button
                        className="bg-green-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded my-2 mr-2 text-xm"
                        onClick={() => {
                          setEditedModel({
                            id: row.id,
                            model: row.model,
                            size: row.size,
                            brand: row.brand,
                            type: row.type,
                            always_available: row.always_available,
                          });
                          setShowModelModal(true);
                        }}
                      >
                        Muokkaa
                      </button>
                      <button
                        className="bg-red-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded my-2 mr-2 text-xm"
                        onClick={() => deleteModel(row.id)}
                      >
                        Poista
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {/* Koot */}
      {active === "sizes" && (
        <div className="flex items-center flex-col py-5">
          <button
            className="bg-green-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded my-2 mr-2 text-xm w-fit"
            onClick={() => {
              setEditedSize(null);
              setShowSizeModal(true);
            }}
          >
            Lisää uusi
          </button>
          <div className="overflow-y-auto" style={{ maxHeight: "60vh" }}>
            <table
              className="table-auto overflow-scroll"
              style={{ minWidth: "500px", width: "60%" }}
            >
              <thead>
                <tr>
                  <th className={styles.th}>ID</th>
                  <th className={styles.th}>Koko</th>
                  <th className={styles.th}>Yksikkö</th>
                  <th className={styles.th}>Tyyppi</th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {allSizes.map((row: SizeRow) => (
                  <tr key={row.id}>
                    <td className={styles.td + " font-bold"}>{row.id}</td>
                    <td className={styles.td}>{row.size}</td>
                    <td className={styles.td}>{row.unit}</td>
                    <td className={styles.td}>{row.type_name}</td>
                    <td className={styles.td + " text-right"}>
                      <button
                        className="bg-green-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded my-2 mr-2 text-xm"
                        onClick={() => {
                          setEditedSize({
                            id: row.id,
                            size: row.size,
                            unit: row.unit,
                            type: row.type,
                            type_name: row.type_name,
                          });
                          setShowSizeModal(true);
                        }}
                      >
                        Muokkaa
                      </button>
                      <button
                        className="bg-red-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded my-2 mr-2 text-xm"
                        onClick={() => deleteSize(row.id)}
                      >
                        Poista
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {showTypeModal ? (
        <AddEditTypeModal
          showAction={setShowTypeModal}
          updateList={fetchAllTypes}
          data={editedType || null}
        />
      ) : null}
      {showBrandModal ? (
        <AddEditBrandModal
          showAction={setShowBrandModal}
          updateList={fetchAllBrands}
          data={editedBrand || null}
        />
      ) : null}
      {showModelModal ? (
        <AddEditModelModal
          showAction={setShowModelModal}
          updateList={fetchAllModels}
          data={editedModel || null}
          brands={allBrands}
          types={allTypes}
          sizes={allSizes}
        />
      ) : null}
      {showSizeModal ? (
        <AddEditSizeModal
          showAction={setShowSizeModal}
          updateList={fetchAllSizes}
          data={editedSize || null}
          types={allTypes}
        />
      ) : null}
      {showTypeConfirm && confirmAction ? (
        <ConfirmModal
          showAction={setShowTypeConfirm}
          header="Sammutintyypin poisto"
          icon="danger"
          message="Oletko varma että haluat poistaa tämän tyypin?"
          confirmAction={confirmAction}
        />
      ) : null}
      {showBrandConfirm && confirmAction ? (
        <ConfirmModal
          showAction={setShowBrandConfirm}
          header="Sammutinmerkin poisto"
          icon="danger"
          message="Oletko varma että haluat poistaa tämän merkin?"
          confirmAction={confirmAction}
        />
      ) : null}
      {showModelConfirm && confirmAction ? (
        <ConfirmModal
          showAction={setShowModelConfirm}
          header="Sammutimallin poisto"
          icon="danger"
          message="Oletko varma että haluat poistaa tämän mallin?"
          confirmAction={confirmAction}
        />
      ) : null}
      {showSizeConfirm && confirmAction ? (
        <ConfirmModal
          showAction={setShowSizeConfirm}
          header="Sammutinkoon poisto"
          icon="danger"
          message="Oletko varma että haluat poistaa tämän koon?"
          confirmAction={confirmAction}
        />
      ) : null}
    </MainWrapper>
  );
};
