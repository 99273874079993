import React from "react";

interface MainWrapperProps {
  titleText: string;
  addButtonTitle?: string;
  addModalToggle?: (v: boolean) => void;
  children?: React.ReactNode;
}

export const MainWrapper: React.FC<MainWrapperProps> = ({
  children,
  titleText,
  addButtonTitle,
  addModalToggle,
}) => {
  return (
    <main className="py-16 mx-5">
      <div className="flex justify-between flex-wrap">
        <h4 className="text-black font-bold text-4xl">{titleText}</h4>
        {addButtonTitle && addModalToggle ? (
          <button
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 border border-green-700 rounded"
            onClick={() => addModalToggle(true)}
          >
            {addButtonTitle}
          </button>
        ) : null}
      </div>
      <div className="mt-4 -mb-3">
        <div className="not-prose relative bg-slate-100 rounded-xl overflow-x-auto">
          <div className="relative rounded-xl">
            <div className="shadow-sm my-8">{children}</div>
          </div>
        </div>
      </div>
    </main>
  );
};
