import React, { useEffect, useState } from "react";
import { api } from "../../api/axiosCalls";
var generator = require("generate-password-browser");

interface DeleteAccountModalProps {
  showAction: (show: boolean) => void;
  email: string;
  refreshAction: () => void;
}

const styles = {
  labelDefault:
    "block uppercase tracking-wide text-gray-700 text-xs font-bold my-2",
  inputDefault:
    "appearance-none block w-full bg-white text-gray-700 border border-gray-400 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500",
  bigPassword: "text-5xl border border-indigo-600 p-5 bg-white",
};

export const DeleteAccountModal: React.FunctionComponent<
  DeleteAccountModalProps
> = ({ showAction, email, refreshAction }) => {
  const deleteAccount = async () => {
    try {
      const createCustomerResponse = await api.put("/accounts/delete", {
        email,
      });
      if (createCustomerResponse.status === 200) {
        showAction(false);
        refreshAction();
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-2xl font-semibold pr-10">
                Poista käyttäjätili
              </h3>
              <button className="p-1 ml-auto border-0 float-right text-3xl leading-none">
                <span
                  className="text-black h-6 w-6 text-lg block"
                  onClick={() => showAction(false)}
                >
                  ❌
                </span>
              </button>
            </div>
            {/*body*/}
            <div className="relative p-6 flex-auto bg-slate-200">
              <form className="w-full max-w-lg">
                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full px-3">
                    <label
                      className={styles.labelDefault + " m-2 text-red-600"}
                    >
                      Haluatko varmasti poistaa käyttäjän? Tilin palautus vaatii
                      järjestelmäylläpitäjän toimia mikäli tili halutaan vielä
                      palauttaa.
                    </label>
                  </div>
                </div>
              </form>
            </div>
            {/*footer*/}
            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => showAction(false)}
              >
                Peruuta
              </button>

              <button
                className="bg-red-500 text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => deleteAccount()}
              >
                Varmista
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};
